import React, { useEffect, useState } from "react";
import BlogDetailsContent from "../../components/blog/BlogDetailsContent";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import { useParams, useNavigate } from "react-router-dom";
import GetBlogDetails from "../../api/getBlogDetails";

const BlogDetails = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogData = await GetBlogDetails(slug);

        // console.log("blogData ====>", blogData);
        if (blogData) {
          setData(blogData);
        } else {
          navigate("/blogs");
        }
      } catch (err) {
        setError(err);
        // console.error("Error fetching blog details:", err);
      } finally {
        setLoading(false);
      }
    };

    // console.log("id ======>", slug);

    fetchData();
  }, [slug]);

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    ); // Loading state
  }

  if (error) {
    return <div>Error loading blog details.</div>; // Error handling
  }

  return (
    <>
      <SEO title="Blog Details || Doob" />
      <Layout>
        {data && (
          <div className="rn-blog-details-area">
            <BlogDetailsContent data={data} />
          </div>
        )}
      </Layout>
    </>
  );
};

export default BlogDetails;
