import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link, useNavigate } from "react-router-dom";

const SlpitOne = () => {
  const navigate = useNavigate();
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img
                src="./images/mplify/about/mplify-about-3.webp"
                alt="split Images"
              />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">Why Choose Mplify?</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  We pride in offering the best in class solutions and services.
                </p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <ul className="split-list">
                  <li>1. Comprehensive Technical Expertise.</li>
                  <li>2. Fast and Dedicated Service.</li>
                  <li>3. State of the art ODC in the capital of Gujarat.</li>
                  <li>4. Deep technical knowhow.</li>
                  <li>5. Most robust product delivery.</li>
                  <li>6. Always keeping up with the technology trends.</li>
                </ul>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="view-more-button mt--35">
                  <Link className="btn-default" to="/who-we-are">
                    Know More
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
