import React from "react";
import { FiUser, FiCalendar } from "react-icons/fi";
import { Helmet } from "react-helmet";

const BlogDetailsContent = ({ data }) => {
  function getSimpleDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = date.getDate();
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(date);

    return `${day} ${monthName} ${year}`;
  }

  const blogbody = data.body;

  let blogbodyUpdated = blogbody.replaceAll(
    "color: rgb(0,0,0)",
    "color: rgb(f,0,0)"
  );

  const blogbodyFinal = blogbodyUpdated.replaceAll(
    /<img([^>]+)>|<span([^>]*?)style="([^"]*?)"([^>]*)>/g,
    (
      match,
      imgAttributes,
      spanAttributes,
      spanStyles,
      spanRest,
      offset,
      string
    ) => {
      // Check if the match is an <img> tag
      if (imgAttributes) {
        // Size to set for the img
        const size = "100%";

        // Modify the <img> tag's width and height to 100%
        const updatedAttributes = imgAttributes
          .replace(/width="[^"]*"/, `width="${size}"`) // Replace width with 100%
          .replace(/height="[^"]*"/, `height="${size}"`) // Replace height with 100%
          .replace(/style="[^"]*"/, (styleMatch) => {
            return styleMatch
              .replace(/width:[^;]+;/, `width:${size};`)
              .replace(/height:[^;]+;/, `height:${size};`);
          });

        // If width or height is missing, add them
        let finalAttributes = updatedAttributes;
        if (!/width=/.test(updatedAttributes)) {
          finalAttributes += ` width="${size}"`;
        }
        if (!/height=/.test(updatedAttributes)) {
          finalAttributes += ` height="${size}"`;
        }

        return `<img${finalAttributes}>`;
      }

      // Check if the match is a <span> tag
      if (spanAttributes) {
        // Remove width and height from the span style
        let updatedSpanStyles = spanStyles
          .replace(/width:[^;]+;/g, "") // Remove width
          .replace(/height:[^;]+;/g, ""); // Remove height

        // Return the updated span tag with cleaned inline styles
        return `<span${spanAttributes} style="${updatedSpanStyles.trim()}"${spanRest}>`;
      }

      return match; // Return the match if it doesn't fit the patterns
    }
  );

  return (
    <>
      {data && (
        <>
          <Helmet>
            {data.meta_title && <title>{data.meta_title}</title>}
            {data.meta_description && (
              <meta name="description" content={data.meta_description} />
            )}
            {data.meta_keywords && (
              <meta name="keywords" content={data.meta_keywords} />
            )}
          </Helmet>
          <div className="post-page-banner rn-section-gapTop">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="content text-center">
                    <div className="page-title">
                      <h1 className="theme-gradient light-mode-blog-title">
                        {data.title}
                      </h1>
                    </div>
                    <ul className="rn-meta-list">
                      <li>
                        <FiUser />

                        {data.author}
                      </li>
                      <li>
                        <FiCalendar />
                        {getSimpleDate(data.created_at)}
                      </li>
                    </ul>
                    <div className="thumbnail alignwide mt--60">
                      <img
                        className="w-100 radius"
                        src={`${process.env.REACT_APP_BACKEND_URL}/${data.thumbnail}`}
                        alt="Blog Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="blog-details-content pt--40 rn-section-gapBottom"
            style={{ padding: "18px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="content">
                    <div
                      className="editor-content"
                      dangerouslySetInnerHTML={{
                        __html: `<span style="color: white;">${blogbodyFinal}</span>`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default BlogDetailsContent;
