import React from "react";

const Copyright = () => {
  return (
    <div className="copyright-area copyright-style-one">
      <div className="container">
        <div className="row align-items-center">
          <div className="copyright-right text-center text-lg-right">
            <p className="copyright-text">
              © {new Date().getFullYear()} Mplify Tech Services Pvt. Ltd. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
