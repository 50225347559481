import React from "react";
import SEO from "../../common/SEO";
import HeaderOne from "../../common/header/HeaderOne";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import CounterUpTwo from "../../elements/counterup/CounterUpTwo";
import AdvanceTabThree from "../../elements/advancetab/AdvanceTabThree";
import { Helmet } from "react-helmet";
import TeamOne from "../../elements/team/TeamOne";

const WhoWeAre = () => {
  return (
    <>
      <Helmet>
        <title>
          {/* Mplify Tech Services | Who We Are | Know More About Our Culture,Vision
          & Mission */}
          Mplify | Your IT Partner
        </title>
        <meta
          name="description"
          content="Mplify is a software technology company established in 2015. We specialize in providing innovative and dependable business solutions to leading software product companies serving Fortune 500 firms across various industries. Mplify's core values of bottom-up culture, transparency, ethics, and customer-centricity guide their mission to empower employees and deliver exceptional service."
        />
        <meta
          name="keywords"
          content="Software Development, Software as a Service (SaaS), Testing as a Service (TaaS), Web App Development, Mobile App Development, Information Technology, Business Solutions, Customer Service, Innovation, Employee Empowerment"
        />
        <meta property="og:url" content="https://mplifytech.com/who-we-are" />
        <meta
          property="og:title"
          content="Mplify Tech: Innovative Business Solutions for Software Companies"
        />
        <meta
          property="og:description"
          content="Mplify empowers businesses with cutting-edge software solutions and exceptional service. Know more about our services, company values, and mission."
        />
        <meta
          property="og:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />{" "}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MplifyTech" />
        <meta
          name="twitter:title"
          content="Mplify Tech: Your IT Partner for Growth"
        />
        <meta name="twitter:creator" content="@MplifyTech" />
        <meta
          name="twitter:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta
          name="twitter:description"
          content="Mplify empowers businesses with cutting-edge software solutions and exceptional service. Learn more about their services, company values, and mission."
        />
        <meta name="business:contact_data:locality" content="Gandhinagar" />
        <meta
          name="business:contact_data:street_address"
          content="A-323, Siddhraj Zori, Sargasan Cross Road, S.G.Highway, Sargasan"
        />
        <meta
          name="business:contact_data:region"
          content="Gandhinagar, Gujarat"
        />
        <meta name="business:contact_data:country_name" content="India" />
        <meta
          name="business:contact_data:email"
          content="contact@mplifytech.com"
        />
        <meta
          name="business:contact_data:phone_number"
          content="91-9512832436"
        />
        <meta
          name="business:contact_data:website"
          content="https://mplifytech.com"
        />
        <meta
          name="keywords"
          content="Blackberry, AB InBev, auco, Arham Enterprise, Architect's Diary, Cylance, Conkart, Agrify Foods"
        />
        <meta
          name="keywords"
          content="Java, Python, PHP, Laravel, Javascript, Typescript, Node, Next, React, HTML, Wordpress, UI/UX, Dart, Kotlin, Flutter, ASP.Net, Docker, Jenkins, Aws, Playwright, Cypress, Codecept, Selenium, Appium, Robot, PyTest, TestNG"
        />
        <meta name="business:contact_data:postal_code" content="382421" />
        <link rel="canonical" href="https://mplifytech.com/who-we-are"></link>
        <meta name="author" content="Mplify Tech Services Pvt. Ltd." />
      </Helmet>

      <SEO title="Who We Are" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/mplify/about/about-us-image.webp)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Know More About</span>
                    </h3>
                  </div>
                  <h2 className="title display-one">Mplify</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />
        <div style={{ padding: "10px" }}>
          {/* Start Service Area  */}
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Know About"
                    title=" Our Organization"
                    description="We are a forward-thinking organization that values integrity and innovation. With a dedicated team of professionals, we strive to provide exceptional services and solutions to our clients. Our commitment to excellence ensures that we meet and exceed expectations, driving business growth and success."
                  />
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}

          {/* Start Service Area  */}
          <div
            className="service-area rn-section-gapBottom"
            style={{ marginTop: "50px" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="content">
                    <h3 className="title">How It Started</h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="mb--10">
                    Established in 2015, our software technology company serves
                    leading software product companies catering to Fortune 500
                    firms, specializing in diverse industries such as finance,
                    banking, insurance, communication, transportation, and
                    healthcare.
                  </p>
                  <div className="readmore-btn">
                    {/* <Link className="btn-read-more" to="our-services">
                    <span>View More</span>
                  </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area  */}

          {/* Our Vision */}
          <div className="rwt-about-area rn-section-gap">
            <div className="container">
              <div className="row row--30 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      src="./images/mplify/images/our-vision.webp"
                      alt="About Images"
                      width="543"
                      height="652"
                    />
                  </div>
                </div>

                <div className="col-lg-7 mt_md--40 mt_sm--40">
                  <div className="content">
                    <div className="section-title">
                      <h2 className="title">
                        Company Vision <br />
                      </h2>
                      <p className="mb--10">
                        Our Vision is to become a top provider of innovative and
                        dependable business solutions that enable organisations
                        to achieve their objectives. By utilising trending
                        technologies, best practises, and a team of
                        knowledgeable experts who are enthusiastic about their
                        work, we aim to offer great customer experiences. Our
                        dedication to excellence, collaboration, and honesty
                        underpins all we do and enables us to forge lasting
                        relationships with our customers and make a meaningful
                        difference in the communities we serve.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Our Mission */}
          <div className="rwt-about-area rn-section-gap">
            <div className="container">
              <div className="row row--30 align-items-center">
                <div className="col-lg-7 mt_md--40 mt_sm--40">
                  <div className="content">
                    <div className="section-title">
                      <h2 className="title">
                        Company Mission <br />
                      </h2>
                      <p className="mb--10">
                        Our Mission is to recognise and utilise each employee's
                        unique abilities in order to enable them to realise
                        their maximum potential. A culture of strength-based
                        leadership, in our opinion, promotes greater
                        participation, productivity, and innovation. In addition
                        to providing chances for development and learning, we
                        work to establish a workplace where people are
                        encouraged to build on and play to their strengths. We
                        want to develop a workforce that is not just highly
                        skilled but also self-assured, driven, and content
                        through regular feedback and assistance. We are creating
                        a stronger, more adaptable company that can adjust to
                        shifting business needs and succeed in the marketplace
                        by investing in the capabilities of our workers.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      src="./images/mplify/images/our-mission.webp"
                      alt="About Images"
                      width="543"
                      height="652"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Start Elements Area  */}
          <div className="rwt-advance-tab-area rn-section-gap">
            <div className="container">
              <div className="row mb--40">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="We Highly Focus On"
                    title="Core Values"
                    description="Our culture and values define who we are. Culture influences how we treat one another. For us, these are not mere words on a page, but these are the words that matter."
                  />
                </div>
              </div>
              <AdvanceTabThree
                serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
                textAlign="text-center"
              />
            </div>
          </div>

          {/* Start Elements Area  */}
          <div className="rwt-counterup-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Our Statistics"
                    title=""
                    description=""
                  />
                </div>
              </div>
              <CounterUpTwo
                column="col-lg-4 col-md-6 col-sm-6 col-12"
                counterStyle="counter-style-1"
                textALign="text-center"
              />
            </div>
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* <FooterTwo /> */}
      </main>
    </>
  );
};

export default WhoWeAre;
