import React from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const BlogList = ({ data, StyleVar }) => {
  const navigate = useNavigate();

  function getSimpleDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = date.getDate();
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(date);

    return `${day} ${monthName} ${year}`;
  }

  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link
            to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}
            className="image"
          >
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/${data.thumbnail}`}
              alt="Blog Image"
            />
          </Link>
        </div>
        <div className="content">
          {/* <ul className="rn-meta-list">
            <li className="separator"></li>
            <li>{getSimpleDate(data.created_at)}</li>
          </ul> */}

          <h4 className="title" style={{ marginTop: "0px" }}>
            <Link to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}>
              {data.title}
            </Link>
          </h4>

          <p
            style={{
              width: "100%",
              marginBottom: "8px",
              marginTop: "15px",
              cursor: "default",
            }}
          >
            {data.summary && data.summary.length > 120
              ? `${data.summary.substring(0, 120)}...`
              : data.summary}
            {"  "}
          </p>

          <div className="readmore-btn">
            <Link
              className="btn-read-more"
              to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}
            >
              <span>Read More</span>
              <MdOutlineKeyboardDoubleArrowRight />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
BlogList.propTypes = {
  data: PropTypes.object,
};
export default BlogList;
