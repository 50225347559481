import React from "react";
import Separator from "../../elements/separator/Separator";
import SEO from "../../common/SEO";
import HeaderOne from "../../common/header/HeaderOne";

const WhatWeDo = () => {
  return (
    <>
      <SEO title="What We Do" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        <Separator />
      </main>
    </>
  );
};
export default WhatWeDo;
