import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  matchPath,
} from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// mplify
import Home from "./pages/mplify/Home";
import WhoWeAre from "./pages/mplify/WhoWeAre";
import WhatWeDo from "./pages/mplify/WhatWeDo";
import OurProducts from "./pages/mplify/OurProducts";
import OurServices from "./pages/mplify/OurServices";
import Career from "./pages/mplify/Career";
import ApplyNowForm from "./common/mplify/job/ApplyNowForm";
import Contact from "./common/mplify/contact/Contact";
import JobApplicationApply from "./pages/mplify/JobApplicationApply";
import BlogGridView from "../src/components/blog/BlogGridView";
import BlogDetails from "./pages/mplify/BlogDetails";
import NoRoutesMatched from "./pages/mplify/NoRoutesMatched";
import FooterTwo from "./common/footer/FooterTwo";
import "./assets/scss/style.scss";
import { ToastContainer } from "react-toastify";

const AppWrapper = () => {
  const location = useLocation();

  // Define paths that should show the footer
  const pathsWithFooter = [
    "/",
    "/who-we-are",
    "/contact-us",
    "/what-we-do",
    "/our-products",
    "/careers",
    "/blogs",
    "/blog-details/:slug",
    "/our-services",
    "/company",
    "/form",
    "/careers/job-description/:id",
  ];

  // Determine if the current location should show the footer
  const showFooter = pathsWithFooter.some((path) =>
    matchPath(path, location.pathname)
  );

  return (
    <>
      <div>
        {/* Other components */}
        <ToastContainer position="bottom-left ml-10" />
      </div>
      <PageScrollTop>
        <Routes>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            element={<Home />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/who-we-are"}`}
            element={<WhoWeAre />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact-us"}`}
            element={<Contact />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/what-we-do"}`}
            element={<WhatWeDo />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/our-products"}`}
            element={<OurProducts />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/careers"}`}
            element={<Career />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/blogs"}`}
            element={<BlogGridView />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/blog-details/:slug"}`}
            element={<BlogDetails />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/our-services"}`}
            element={<OurServices />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/company"}`}
            element={<Home />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/form"}`}
            element={<ApplyNowForm />}
          />
          <Route
            exact
            path="/careers/job-description/:id"
            element={<JobApplicationApply />}
          />
          <Route path="*" element={<NoRoutesMatched />} />
        </Routes>
        {showFooter && <FooterTwo />}
      </PageScrollTop>
    </>
  );
};

const App = () => (
  <Router>
    <AppWrapper />
  </Router>
);

export default App;
