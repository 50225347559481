import React from "react";
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaRegHandshake } from "react-icons/fa";
import { GiStairsGoal } from "react-icons/gi";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div
                    className="icon"
                    style={{ display: "flex", gap: "30px" }}
                  >
                    <h4 className="title">Growth</h4>
                    <BsGraphUpArrow style={{ color: "#F79424" }} />
                  </div>
                  <div className="inner">
                    <p>
                      {" "}
                      Converse sales opportunities or your project idea with us
                      and let us be partner to amplify together.
                    </p>
                    <br />
                    <p>
                      <a href="mailto:growth@mplifytech.com">
                        growth@mplifytech.com
                      </a>
                    </p>
                    <p>
                      <a href="tel:+91-9978932436">+91-9978932436</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div
                    className="icon"
                    style={{ display: "flex", gap: "30px" }}
                  >
                    <h4 className="title">Career</h4>
                    <GiStairsGoal style={{ color: "#01A64F" }} />
                  </div>
                  <div className="inner">
                    <p>
                      {" "}
                      Join us and lets work together to realize our potential
                      and solve real life problems with creative software
                      engineering.
                    </p>
                    <br />
                    <p>
                      <a href="mailto:recruitment@mplifytech.com">
                        recruitment@mplifytech.com
                      </a>
                    </p>
                    <p>
                      <a href="tel:+91-9512832436">+91-9512832436</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div
                    className="icon"
                    style={{ display: "flex", gap: "30px" }}
                  >
                    <h4 className="title">Meet</h4>
                    <FaRegHandshake style={{ color: "#F79424" }} />
                  </div>
                  <div className="inner">
                    <p>
                      A-323, Siddhraj Zori,
                      <br /> Sargasan Cross Road,
                      <br /> Sargasan, Gandhinagar - 382421,
                      <br /> Gujarat - INDIA.
                    </p>
                    <p>
                      <a href="mailto:contact@mplifytech.com">
                        contact@mplifytech.com
                      </a>
                    </p>
                    <p>
                      <a href="tel:+91-9512832436">+91-9512832436</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
