import React, { useState } from "react";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import ApplyNowForm from "../../common/mplify/job/ApplyNowForm";
import JobDetails from "../../common/mplify/job/JobDetails";
import { useParams } from "react-router-dom";
import LoaderButton from "../../components/loader";

const JobApplicationApply = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  return (
    <>
      <span
        style={{
          opacity: loading && "0",
        }}
      >
        <SEO title="Job Application Form" />
      </span>
      <Layout>
        <div
          className="main-content"
          style={{
            opacity: loading && "0",
          }}
        >
          {/* Start Contact Area  */}
          <div className=" rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Mplify Careers"
                    title="Job Application Form"
                    description=""
                  />
                </div>
              </div>
              <div className="row mt--40 row--15">
                <div className="col-lg-7">
                  <JobDetails jobId={id} setLoading={setLoading} />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                  <ApplyNowForm jobId={id} formStyle="contact-form-1" />
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};

export default JobApplicationApply;
