import React, { useEffect } from "react";
import SEO from "../../SEO";
import Layout from "../../Layout";
import BreadcrumbOne from "../../../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../../../elements/sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Mplify | Let's Connect</title>
        <meta
          name="description"
          content="Mplify is a software technology company established in 2015. They specialize in providing innovative and dependable business solutions to leading software product companies serving Fortune 500 firms across various industries. Mplify's core values of bottom-up culture, transparency, ethics, and customer-centricity guide their mission to empower employees and deliver exceptional service."
        />
        <meta property="og:url" content="https://mplifytech.com/contact-us" />
        <meta property="og:title" content="Mplify Tech Service | Contact Us" />
        <meta property="og:description" content="Get In Touch With Mplifiers" />
        <meta
          property="og:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />{" "}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MplifyTech" />
        <meta name="twitter:creator" content="@MplifyTech" />
        <meta
          name="twitter:title"
          content="Mplify Tech: Your IT Partner for Growth"
        />
        <meta
          name="twitter:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta
          name="twitter:description"
          content="Mplify Tech empowers businesses with reliable IT solutions. We specialize in web & mobile app dev, SDaaS, TaaS, and more. Get a free quote today!"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mplifytech.com/contact-us"></link>
        <meta name="author" content="Mplify Tech Services Pvt. Ltd." />
        <meta name="business:contact_data:locality" content="Gandhinagar" />
        <meta
          name="business:contact_data:street_address"
          content="A-323, Siddhraj Zori, Sargasan Cross Road, S.G.Highway, Sargasan"
        />
        <meta
          name="business:contact_data:region"
          content="Gandhinagar, Gujarat"
        />
        <meta name="business:contact_data:country_name" content="India" />
        <meta
          name="business:contact_data:email"
          content="contact@mplifytech.com"
        />
        <meta
          name="business:contact_data:phone_number"
          content="91-9512832436"
        />
        <meta
          name="business:contact_data:website"
          content="https://mplifytech.com"
        />
        <meta
          name="keywords"
          content="Blackberry, AB InBev, auco, Arham Enterprise, Architect's Diary, Cylance, Conkart, Agrify Foods"
        />
        <meta
          name="keywords"
          content="Java, Python, PHP, Laravel, Javascript, Typescript, Node, Next, React, HTML, Wordpress, UI/UX, Dart, Kotlin, Flutter, ASP.Net, Docker, Jenkins, Aws, Playwright, Cypress, Codecept, Selenium, Appium, Robot, PyTest, TestNG"
        />
        <meta name="business:contact_data:postal_code" content="382421" />
        <link rel="canonical" href="https://mplifytech.com/contact-us"></link>
      </Helmet>

      <SEO title="Contact" />
      <Layout>
        {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                /> */}
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Contact Form"
                    title="Connect With Us"
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
