import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { Link, scroller } from "react-scroll";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import SEO from "../../common/SEO";
import Separator from "../../elements/separator/Separator";
import ServiceData from "../../data/service/ServiceList.json";
import Layout from "../../common/Layout";
import LeftService from "../../elements/about/LeftService";
import RightService from "../../elements/about/RightService";
import { BannerActivation } from "../../utils/script";
import ServiceCard from "../../elements/about/serviceCard";
import LazyLoad from "react-lazyload";

const OurServices = () => {
  const ProductContext = createContext();
  const location = useLocation();
  const navigate = useNavigate();
  const prevLocationRef = useRef(location);

  const handleKnowMoreClick = (id) => {
    navigate(`/our-services/#${id}`);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const idFromQuery = queryParams.get("id");
    const idFromHash = location.hash ? location.hash.substring(1) : null;

    console.log("Query Params:", queryParams.toString());
    console.log("ID from Query:", idFromQuery);
    console.log("ID from Hash:", idFromHash);
  }, [location.search, location.hash]);

  const [isMobileTabView, setIsMobileTabView] = useState(
    window.innerWidth < 900
  );

  const products = useMemo(() => ServiceData, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileTabView(window.innerWidth < 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {/* Mplify Tech Services: Experts In Delivering Reliable and Scalable IT
          Solutions */}
          Mplify | Experience Quality
        </title>
        <meta
          name="description"
          content="Mplify Tech - As a process and development expert in IT Industry, we have experience in delivering custom and robust IT Solutions. We offer web & mobile app development, SDaaS (Software Development as a Service), TaaS (Testing as a Service), and more. Boost your online presence, optimize operations, and achieve your goals with Mplify Tech!"
        />
        <meta
          name="keywords"
          content="Responsive web design,scalable web applications,cross-platform compatibility,SDaaS, non-functional testing, test execution and analysis, Software Development as a Service."
        />
        <meta
          name="keywords"
          content="Web Application Development,Web App Development, web-based solution for e-commerce,custom ecommerce solutions,enterprise level software solutions,performance testing,continuous testing,functional testing."
        />
        <meta
          name="keywords"
          content="Quality assurance testing,maintenance and support,testing as a service,TaaS,automation testing,manual testing,API testing,end-to-end testing,on-demand testing,regression testing."
        />
        <meta
          name="keywords"
          content="Mobile Application development,Mobile App development,iOS app development,Android app development."
        />
        <meta property="og:url" content="https://mplifytech.com/our-services" />
        <meta
          property="og:title"
          content="Mplify Tech Services | Our Services | Boost Your Business with IT Solutions"
        />
        <meta
          property="og:description"
          content="Mplify Tech Services provides comprehensive IT solutions to businesses, including web & mobile app development, SDaaS, TaaS, and more. Contact us today for a free consultation!"
        />
        <meta
          property="og:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />{" "}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MplifyTech" />
        <meta
          name="twitter:title"
          content="Mplify Tech: Your IT Partner for Growth"
        />
        <meta name="twitter:creator" content="@MplifyTech" />
        <meta
          name="twitter:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta
          name="twitter:description"
          content="Mplify Tech empowers businesses with reliable IT solutions. We specialize in web & mobile app dev, SDaaS, TaaS, and more. Get a free quote today!"
        />
        <meta name="robots" content="index, follow" />
        <meta name="business:contact_data:locality" content="Gandhinagar" />
        <meta
          name="business:contact_data:street_address"
          content="A-323, Siddhraj Zori, Sargasan Cross Road, S.G.Highway, Sargasan"
        />
        <meta
          name="business:contact_data:region"
          content="Gandhinagar, Gujarat"
        />
        <meta name="business:contact_data:country_name" content="India" />
        <meta
          name="business:contact_data:email"
          content="contact@mplifytech.com"
        />
        <meta
          name="business:contact_data:phone_number"
          content="91-9512832436"
        />
        <meta
          name="business:contact_data:website"
          content="https://mplifytech.com"
        />
        <meta name="business:contact_data:postal_code" content="382421" />
        <link rel="canonical" href="https://mplifytech.com/our-services"></link>
        <meta name="author" content="Mplify Tech Services Pvt. Ltd." />
        <meta
          name="keywords"
          content="Blackberry, AB InBev, auco, Arham Enterprise, Architect's Diary, Cylance, Conkart, Agrify Foods"
        />
        <meta
          name="keywords"
          content="Java, Python, PHP, Laravel, Javascript, Typescript, Node, Next, React, HTML, Wordpress, UI/UX, Dart, Kotlin, Flutter, ASP.Net, Docker, Jenkins, Aws, Playwright, Cypress, Codecept, Selenium, Appium, Robot, PyTest, TestNG"
        />
      </Helmet>

      <SEO title="Our Service" />
      <Layout
        className="page-wrapper"
        key={`appProduct-${
          prevLocationRef.current.pathname === location.pathname
        }-${location.hash}`}
      >
        <div className="main-content">
          <Slider
            className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
            {...BannerActivation}
          >
            {products &&
              products.map((data, index) => (
                <div key={index} className="single-slide">
                  <div
                    className="height-850 bg-overlay bg_image"
                    style={{
                      backgroundImage: data.image
                        ? `url(${process.env.PUBLIC_URL}${data.image})`
                        : "none",
                    }}
                  >
                    <div className="container">
                      <div className="row row--30 align-items-center">
                        <div className="order-2 order-lg-1 col-lg-7">
                          <div className="inner text-start">
                            {data.title && (
                              <h1
                                dangerouslySetInnerHTML={{
                                  __html: data.title,
                                }}
                              ></h1>
                            )}
                            {data.short_description && (
                              <p
                                className="description"
                                dangerouslySetInnerHTML={{
                                  __html: data.short_description,
                                }}
                              ></p>
                            )}
                            <div
                              className="button-group mt--30"
                              style={{ cursor: "pointer" }}
                            >
                              <Link
                                onClick={() => handleKnowMoreClick(data.id)}
                                className="btn-default"
                                to={data.id}
                                smooth={true}
                                offset={-70} // Adjust this value according to your header height
                              >
                                Know More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </LazyLoad> */}
                </div>
              ))}
          </Slider>

          {/* End Slider Area */}

          <Separator />

          {isMobileTabView ? (
            <>
              <div className="">
                {ServiceData.map((service) => (
                  <div id={service.id}>
                    <ServiceCard
                      key={service.id}
                      service={service}
                      id={service.id}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div id="1">
                <LeftService id={1} />
              </div>

              <div id="2">
                <RightService id={2} />
              </div>

              <div id="3">
                <LeftService id={3} />
              </div>

              <div id="4">
                <RightService id={4} />
              </div>
            </>
          )}
          <Separator />
        </div>
      </Layout>
    </>
  );
};

export default OurServices;
