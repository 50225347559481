export default async function GetBlogDetails(id) {
  const apiEndPoint = `${process.env.REACT_APP_BACKEND_URL}/api/blogs/slug/${id}`;
  try {
    const response = await fetch(apiEndPoint);

    if (response) {
    }

    const BlogData = await response.json();

    if (response.ok && BlogData && BlogData.data) {
      return BlogData.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  } finally {
  }
}
