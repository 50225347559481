import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    title: "Application",
    description: "Apply online via our website, email, LinkedIn or Indeed.",
  },
  {
    id: "2",
    title: "Screening",
    description:
      "Our team reviews applications for fit with open positions. Top candidates to move forward.",
  },
  {
    id: "3",
    title: "Technical Interview",
    description:
      "Discuss skills and experience with the hiring manager or senior team member for the roles, showcasing projects and accomplishments.",
  },
  {
    id: "4",
    title: "HR Round",
    description:
      "Friendly HR conversation to understand you, and your career goals. Discuss about our expectation and company policies.",
  },
];

const TimelineTwo = ({ classVar }) => {
  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {TimelineData &&
          TimelineData.map((data, index) => (
            <div
              className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`}
              key={index}
            >
              <div className="rn-timeline">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h6 className="title">{data.title && data.title}</h6>
                </ScrollAnimation>
                <div className="progress-line">
                  <div className="line-inner"></div>
                </div>
                <div className="progress-dot">
                  <div className="dot-level">
                    <div className="dot-inner"></div>
                  </div>
                </div>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="description">
                    {data.description && data.description}
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelineTwo;
