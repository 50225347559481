import React from "react";
// import './JobDescription.module.scss'
import { Link } from "react-router-dom";

function Job({ id, title, subtitle }) {
  let link = "job-description" + id;
  return (
    <div className="box job-list">
      <div className="box-col box-80 ">
        <Link to={`/careers/job-description/${id}`}>
          <h4 className="color">{title}</h4>
        </Link>
        <span className="function">With Relevant Experience Of {subtitle}</span>
      </div>
      <div className="box-col box-20 text-right">
        {/* <a className={`btn-default btn-small round btn-icon`} href={link} >
                    Apply
                </a> */}

        <Link
          to={`/careers/job-description/${id}`}
          className={`btn-default btn-small round btn-icon`}
        >
          Apply
        </Link>
      </div>
    </div>
  );
}

export default Job;
