import SEO from "../../common/SEO";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderOne from "../../common/header/HeaderOne";
// import Jobs from "../../common/mplify/job/Jobs";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import AccordionOne from "../../elements/accordion/AccordionOne";
import AdvanceTabTwo from "../../elements/advancetab/AdvanceTabTwo";
import TimelineTwo from "../../elements/timeline/TimelineTwo";
import Jobs from "../../common/mplify/job/Jobs";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import TestimonialThree from "../../elements/testimonial/TestimonialThree";

const Career = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {/* Mplify Tech Services: Embark on the journey of growth and success */}
          Mplify | Let's Innovate together
        </title>
        <meta
          name="description"
          content="Join Mplify, a leading provider of Software Development and Testing as a Service. We are dedicated to delivering innovative solutions and high-quality software for clients across various industries. As a part of our team, you'll have the opportunity to work on cutting-edge projects, collaborate with talented professionals, and grow your career in a dynamic and supportive environment."
        />
        <meta
          name="keywords"
          content="careers, Mplify, IT jobs, software development, web development, mobile development, work-life balance, continuous learning"
        />
        <meta property="og:url" content="https://mplifytech.com/our-services" />
        <meta
          property="og:title"
          content="Mplify Tech Services | Careers | Where Growth Meets Innovation"
        />
        <meta
          property="og:description"
          content="Are you ready to take your engineering career to the next level? At Mplify, we’re on the lookout for passionate engineers to join our dynamic team in delivering top-notch Software Development and Testing as a Service. Be part of an innovative environment where your ideas matter, your skills grow, and your work makes a real impact. If you’re eager to tackle exciting challenges and shape the future of technology with us, apply today and let’s build something amazing together!"
        />
        <meta
          name="keywords"
          content="Blackberry, AB InBev, auco, Arham Enterprise, Architect's Diary, Cylance, Conkart, Agrify Foods"
        />
        <meta
          name="keywords"
          content="Java, Python, PHP, Laravel, Javascript, Typescript, Node, Next, React, HTML, Wordpress, UI/UX, Dart, Kotlin, Flutter, ASP.Net, Docker, Jenkins, Aws, Playwright, Cypress, Codecept, Selenium, Appium, Robot, PyTest, TestNG"
        />
        <meta
          property="og:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />{" "}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MplifyTech" />
        <meta
          name="twitter:title"
          content="Mplify Tech: Your IT Partner for Growth"
        />
        <meta name="twitter:creator" content="@MplifyTech" />
        <meta
          name="twitter:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta
          name="twitter:description"
          content="Are you ready to take your engineering career to the next level? At Mplify, we’re on the lookout for passionate engineers to join our dynamic team in delivering top-notch Software Development and Testing as a Service. Be part of an innovative environment where your ideas matter, your skills grow, and your work makes a real impact. If you’re eager to tackle exciting challenges and shape the future of technology with us, apply today and let’s build something amazing together!"
        />
        <meta name="robots" content="index, follow" />
        <meta name="business:contact_data:locality" content="Gandhinagar" />
        <meta
          name="business:contact_data:street_address"
          content="A-323, Siddhraj Zori, Sargasan Cross Road, S.G.Highway, Sargasan"
        />
        <meta
          name="business:contact_data:region"
          content="Gandhinagar, Gujarat"
        />
        <meta name="business:contact_data:country_name" content="India" />
        <meta
          name="business:contact_data:email"
          content="contact@mplifytech.com"
        />
        <meta
          name="business:contact_data:phone_number"
          content="91-9512832436"
        />
        <meta
          name="business:contact_data:website"
          content="https://mplifytech.com"
        />
        <meta name="business:contact_data:postal_code" content="382421" />
        <meta name="author" content="Mplify Tech Services Pvt. Ltd." />
        <link rel="canonical" href="https://mplifytech.com/careers"></link>
      </Helmet>
      <SEO title="Career" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        <Separator />
        <div style={{ padding: "10px" }}>
          {/* Start Slider Area  */}
          <div
            className="slider-area slider-style-1 height-850 bg_image"
            data-black-overlay="7"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/mplify/images/career-image-01.webp)`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner pt--80 text-center">
                    <div>
                      <h3 className="rn-sub-badge">
                        <span className="theme-gradient">
                          Be Part of Something Exciting
                        </span>
                      </h3>
                    </div>
                    <h1 className="title display-one">
                      Challenge Yourself, Grow Your Skills
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Slider Area  */}

          <Separator />

          {/* Start Elements Area  */}
          <div className="rwt-advance-tab-area rn-section-gap">
            <div className="container">
              <div className="row mb--40">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Grow in Career"
                    title="Learn and Work with Us"
                    description=""
                  />
                </div>
              </div>
              {isMobile ? <TestimonialThree /> : <AdvanceTabTwo />}
            </div>
          </div>
          {/* End Elements Area  */}

          {/* <Separator /> */}
          {/* start job Area */}
          <Jobs />

          {/* <Separator /> */}

          <div className="rwt-timeline-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Process"
                    title=" Our Hiring Process"
                    description={
                      "The hiring process can be exciting and daunting at the same time! We’d like to offer some clarity and insight into the hiring process at Mplify, in hopes that it makes all our candidates feel more at ease."
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 offset-lg-1 mt--50">
                  <TimelineTwo classVar="" />
                </div>
              </div>
            </div>
          </div>
          {/* <Separator /> */}

          {/* Start Accordion Area  */}
          <div className="rn-accordion-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Most Asked"
                    title="FAQ's For  Job"
                    description=""
                  />
                </div>
              </div>
              <div className="row mt--35 row--20">
                <div className="col-lg-10 offset-lg-1">
                  <AccordionOne customStyle="" />
                </div>
              </div>
            </div>
          </div>
          {/* End Accordion Area  */}
          {/* <Separator /> */}

          {/* <FooterTwo /> */}
        </div>
      </main>
    </>
  );
};

export default Career;
