import React from "react";
import { useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import ServiceData from "../../data/service/ServiceList.json";

const ServiceTwo = ({ textAlign, cardStyle }) => {
  const navigate = useNavigate();
  return (
    <div className="row row--15 service-wrapper">
      {ServiceData &&
        ServiceData.map((val, i) => (
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className={`card-box ${cardStyle} ${textAlign}`}>
                <div className="inner">
                  {val.image && (
                    <div className="image">
                      <a
                        href={`#${val.id}`}
                        onClick={() => {
                          navigate(`/our-services/#${val.id}`);
                        }}
                      >
                        <img src={`${val.image}`} alt="card Images" />
                      </a>
                    </div>
                  )}
                  <div className="content">
                    {val.title && (
                      <h4 className="title mb--20">
                        <a
                          href={`#${val.id}`}
                          onClick={() => {
                            navigate(`/our-services/#${val.id}`);
                          }}
                          dangerouslySetInnerHTML={{ __html: val.title }}
                        ></a>
                      </h4>
                    )}
                    {val.short_description && (
                      <p
                        className="description b1 color-gray mb--0"
                        dangerouslySetInnerHTML={{
                          __html: val.short_description,
                        }}
                      ></p>
                    )}

                    <a
                      className="btn-default btn-small btn-border"
                      href={`#${val.id}`}
                      onClick={() => {
                        navigate(`/our-services/#${val.id}`);
                      }}
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        ))}
    </div>
  );
};
export default ServiceTwo;
