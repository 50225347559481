import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageScrollTop = (props) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    console.log("scroll to top -->", location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

export default PageScrollTop;
