import { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import HeaderOne from "../../common/header/HeaderOne";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import AppProductDetails from "../../common/mplify/product/AppProductDetails";
import WebProductDetails from "../../common/mplify/product/WebProductDetails";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const OurProducts = () => {
  const [getId, setId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const idFromQuery = queryParams.get("title");
    const idFromHash = location.hash ? location.hash.substring(1) : null;

    console.log("title from query-->", idFromQuery);
    console.log("title from hash-->", idFromHash);

    // Prefer hash fragment over query parameter
    if (idFromHash) {
      setId(idFromHash);
    } else if (idFromQuery) {
      setId(idFromQuery);
    } else {
      setId(null);
    }
  }, [location.search, location.hash]);

  return (
    <>
      <Helmet>
        <title>
          {/* Mplify Tech Services | Innovative Solutions Build With Excellence */}
          Mplify | Innovative Solutions Built With Excellence
        </title>
        <meta
          name="description"
          content="Mplify Tech Services builds user-friendly mobile and web applications to empower your business and simplify your life. Explore our mobile apps (BLENDIN, MYT, MangOO, Vinty) and web applications (JOYREJOY)."
        />
        <meta name="author" content="Mplify Tech Services" />
        <meta
          name="keywords"
          content="BLENDIN, design news app, architecture news app, art news app, interior design app"
        />
        <meta
          name="keywords"
          content="MYT,location-based task reminder app, voice notification task reminder, assign tasks by location, productivity app, time management app"
        />
        <meta
          name="keywords"
          content="MangOO, convenient shopping app, secure payment, order management, Buy mangoes and other yummy snacks online, free-delivery within Gujarat"
        />
        <meta
          name="keywords"
          content="Vinty, buy screws and nails online, user-friendly hardware app, order tracking app, product statistics app for hardware"
        />
        <meta
          name="keywords"
          content="JOYREJOY, pre-loved toys marketplace, sustainable shopping platform, buy and sell baby products online, multivendor marketplace, verified products marketplace, seller portal, buyer portal, admin portal for marketplace"
        />
        <meta property="og:url" content="https://mplifytech.com/our-products" />
        <meta
          property="og:title"
          content="Mplify Tech Services | Innovative Mobile & Web Applications"
        />
        <meta
          property="og:description"
          content="Mplify Tech Services: Experts in mobile & web app development (SDaaS, TaaS). We deliver custom solutions for businesses. Explore our portfolio (BLENDIN, MYT, MangOO, Vinty)."
        />
        <meta property="og:site_name" content="Mplify Tech Services" />
        <meta
          property="og:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta name="twitter:site" content="@Mplifytech" />{" "}
        <meta
          name="twitter:title"
          content="Mplify Tech Services | Innovative Mobile & Web Applications"
        />
        <meta
          name="twitter:description"
          content="Need a location-based task reminder app? Try MYT by Mplify Tech Services! We build innovative mobile & web apps for various needs. Explore JOYREJOY, MangOO, Vinty & more!"
        />
        <meta
          name="keywords"
          content="Blackberry, AB InBev, auco, Arham Enterprise, Architect's Diary, Cylance, Conkart, Agrify Foods"
        />
        <meta
          name="keywords"
          content="Java, Python, PHP, Laravel, Javascript, Typescript, Node, Next, React, HTML, Wordpress, UI/UX, Dart, Kotlin, Flutter, ASP.Net, Docker, Jenkins, Aws, Playwright, Cypress, Codecept, Selenium, Appium, Robot, PyTest, TestNG"
        />
        <meta name="twitter:creator" content="@Mplifytech" />
        <meta
          name="twitter:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta name="business:contact_data:locality" content="Gandhinagar" />
        <meta
          name="business:contact_data:street_address"
          content="A-323, Siddhraj Zori, Sargasan Cross Road, S.G.Highway, Sargasan"
        />
        <meta
          name="business:contact_data:region"
          content="Gandhinagar, Gujarat"
        />
        <meta name="business:contact_data:country_name" content="India" />
        <meta
          name="business:contact_data:email"
          content="contact@mplifytech.com"
        />
        <meta
          name="business:contact_data:phone_number"
          content="91-9512832436"
        />
        <meta
          name="business:contact_data:website"
          content="https://mplifytech.com"
        />
        <meta name="business:contact_data:postal_code" content="382421" />
        <link rel="canonical" href="https://mplifytech.com/our-products"></link>
      </Helmet>
      <SEO title="Our Products" />
      <main className="page-wrapper">
        <HeaderOne
          key={`appProduct-${location.hash}`}
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        <Separator />

        {/* Start mobile application Area  */}
        <div
          id="mobile-application"
          className="rwt-timeline-area rn-section-gap"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Excited to know about"
                  title="Our Mobile Applications"
                />
              </div>
            </div>

            <Separator />

            <div className="row">
              <div className="col-lg-12">
                <div id="appProduct">
                  <AppProductDetails id={getId} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Separator />

        {/* Start Web application Area  */}
        <div id="web-application" className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Excited to know about"
                  title="Our Web Applications"
                />
              </div>
            </div>

            <Separator />

            <div className="row">
              <div className="col-lg-12">
                <div id="webProduct">
                  <WebProductDetails id={getId} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator />
        {/* <FooterTwo /> */}
      </main>
    </>
  );
};

export default OurProducts;
