import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiTwitter, FiLinkedin } from "react-icons/fi";
import Copyright from "./Copyright";
import { RiTwitterXFill } from "react-icons/ri";

const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];

const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;

const FooterTwo = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className="rn-footer footer-style-default variation-two">
        <CalltoActionSeven />
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">
                    {footerIntemOne &&
                      footerIntemOne.title &&
                      footerIntemOne.title}
                  </h4>
                  {indexOneLink && (
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexOneLink.map((data, index) => (
                          <li key={index}>
                            <a
                              href={`#${data.ref}`}
                              onClick={() => {
                                navigate(data.url);
                                window.scrollTo(0, 0);
                              }}
                            >
                              {data.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">
                      {footerIntemFour &&
                        footerIntemFour.title &&
                        footerIntemFour.title}
                    </h4>
                    {indexThreeLink && (
                      <div className="inner">
                        <ul className="footer-link link-hover">
                          {indexThreeLink.map((data, index) => (
                            <li key={index}>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(data.url);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {data.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                {footerIntemThree && (
                  <div className="rn-footer-widget">
                    <h4 className="title">
                      {footerIntemThree.title && footerIntemThree.title}
                    </h4>
                    {indexTwoLink && (
                      <div className="inner">
                        <ul className="footer-link link-hover">
                          {indexTwoLink.map((data, index) => (
                            <li key={index}>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(data.url);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {data.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                {footerIntemFive && (
                  <div className="rn-footer-widget">
                    <h4 className="title">
                      {footerIntemFive.title && footerIntemFive.title}
                    </h4>
                    <div className="inner">
                      <h6 className="subtitle">
                        <p>
                          A-323, Siddhraj Zori,
                          <br /> Sargasan Cross Road,
                          <br /> Sargasan, Gandhinagar - 382421,
                          <br /> Gujarat - INDIA.
                        </p>
                      </h6>
                      <ul className="social-default justify-content-start">
                        <li>
                          <a
                            href="https://in.linkedin.com/company/mplifytech"
                            target="_blank"
                          >
                            <img
                              style={{ height: "40px", width: "40x" }}
                              src={`${process.env.PUBLIC_URL}/images/social/linkedin.png`}
                              alt="Sun images"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/Mplifytech"
                            target="_blank"
                          >
                            <img
                              style={{ height: "40px", width: "40x" }}
                              src={`${process.env.PUBLIC_URL}/images/social/twitter.png`}
                              alt="Sun images"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://wa.me/919978932436"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="WhatsApp"
                          >
                            <img
                              style={{ height: "40px", width: "40px" }}
                              src={`${process.env.PUBLIC_URL}/images/social/whatsapp.png`}
                              alt="WhatsApp logo"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/Mplify-Technologies-431395953719574"
                            target="_blank"
                          >
                            <img
                              style={{ height: "40px", width: "40px" }}
                              src={`${process.env.PUBLIC_URL}/images/social/facebook.png`}
                              alt="Sun images"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
      <Copyright />
    </>
  );
};

export default FooterTwo;
