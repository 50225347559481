import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import ProductData from "../../../data/product/product.json";
import WebProductData from "../../../data/product/webProduct.json";

const ProductList = ({ column, teamStyle, type }) => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const scrollLeft = () => {
    const productWidth = containerRef.current?.firstChild?.offsetWidth || 400; // Get the width of a single product or use a default value
    containerRef.current.scrollBy({
      left: -productWidth,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    const productWidth = containerRef.current?.firstChild?.offsetWidth || 400; // Get the width of a single product or use a default value
    containerRef.current.scrollBy({
      left: productWidth,
      behavior: "smooth",
    });
  };

  if (type === "mobile") {
    return (
      <div className="product-container">
        {ProductData.length > 1 && (
          <div className="scroll-btn left" onClick={scrollLeft}>
            &#9664;
          </div>
        )}

        <div className="product-container-inner" ref={containerRef}>
          {ProductData &&
            ProductData.map((data, index) => (
              <div className={`${column}`} key={index}>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className={`rn-team ${teamStyle}`}>
                    <div className="inner product-box">
                      <figure className="thumbnail">
                        <img src={data.image} alt="Corporate React Template" />
                      </figure>
                      <figcaption className="content">
                        <h2 className="title">{data.title && data.title}</h2>
                        <h6 className="subtitle theme-gradient">
                          {data.subTitle && data.subTitle}
                        </h6>
                        <p className="description b1 color-gray mb--0">
                          {data.description && data.description.slice(0, 95)}
                          ...
                        </p>
                        <a
                          className="btn-default btn-small btn-border"
                          style={{ marginTop: "18px" }}
                          href={`#${data.title}`}
                          onClick={() => {
                            navigate(`/our-products/#${data.title}`);
                          }}
                        >
                          Read More
                        </a>
                      </figcaption>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            ))}
        </div>

        {ProductData.length > 1 && (
          <div className="scroll-btn right" onClick={scrollRight}>
            &#9654;
          </div>
        )}
      </div>
    );
  }

  if (type === "web") {
    return (
      <>
        {WebProductData && (
          <div className="product-container">
            {WebProductData.length > 1 && (
              <div className="scroll-btn left" onClick={scrollLeft}>
                &#9664;
              </div>
            )}

            <div className="product-container-inner" ref={containerRef}>
              {WebProductData.map((data, index) => (
                <div className={`${column}`} key={index}>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <div className={`rn-team ${teamStyle}`}>
                      <div className="inner product-box">
                        <figure className="thumbnail">
                          <img
                            src={data.image}
                            alt="Corporate React Template"
                          />
                        </figure>
                        <figcaption className="content">
                          <h2 className="title">{data.title && data.title}</h2>
                          <h6 className="subtitle theme-gradient">
                            {data.subTitle && data.subTitle}
                          </h6>
                          <p className="description b1 color-gray mb--0">
                            {data.description && data.description.slice(0, 95)}
                            ...
                          </p>
                          <a
                            className="btn-default btn-small btn-border"
                            style={{ marginTop: "18px" }}
                            href={`#${data.title}`}
                            onClick={() => {
                              navigate(`/our-products/#${data.title}`);
                            }}
                          >
                            Read More
                          </a>
                        </figcaption>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              ))}
            </div>

            {WebProductData.length > 1 && (
              <div className="scroll-btn right" onClick={scrollRight}>
                &#9654;
              </div>
            )}
          </div>
        )}
      </>
    );
  }
};

export default ProductList;
