import React from "react";
import "./ServiceCard.css"; // Import the CSS file

const ServiceCard = ({ service, id }) => {
  return (
    <div className="service-card">
      <h3 className="service-title">{service.title}</h3>
      <img src={service.image} alt={service.title} className="service-image" />
      <div className="service-content">
        <p className="service-description">{service.description_1}</p>
        <h4 className="service-heading">{service.details.heading}</h4>
        <ul className="service-points">
          {service.details.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        <p className="service-description">{service.description_2}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
