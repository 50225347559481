// import PropTypes from "prop-types";
// import React from 'react';
// import { Link } from "react-router-dom";

// const Logo = ({ image, image2 }) => {
//     return (
//         <div className="logo">
//             <Link to={process.env.PUBLIC_URL + "/"}>
//                 <img className="logo-light" src={process.env.PUBLIC_URL + image} alt="Mplify Logo" />
//                 <img className="logo-dark" src={process.env.PUBLIC_URL + image2} alt="Mplify Logo" />
//             </Link>
//         </div>
//     )
// }
// Logo.propTypes = {
//     image: PropTypes.string
// };

// export default Logo;
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

const Logo = ({ image, image2 }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    // Navigate to home URL
    navigate(process.env.PUBLIC_URL + "/");
    // Reload the page
    //window.location.reload();
    window.scrollTo(0, 0);
  };

  return (
    <div className="logo">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault(); // Prevent default link behavior
          handleLogoClick();
        }}
      >
        <img
          className="logo-light"
          src={process.env.PUBLIC_URL + image}
          alt="Mplify Logo"
        />
        <img
          className="logo-dark"
          src={process.env.PUBLIC_URL + image2}
          alt="Mplify Logo"
        />
      </a>
    </div>
  );
};

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  image2: PropTypes.string.isRequired,
};

export default Logo;
