export default async function GetBlogList() {
  const apiEndPoint = `${process.env.REACT_APP_BACKEND_URL}/api/blogs`;
  try {
    const response = await fetch(apiEndPoint);

    if (response.ok) {
      const BlogData = await response.json();
      return BlogData.data.data;
    }
  } catch (error) {
    console.log(error);
    return error;
  } finally {
  }
}
