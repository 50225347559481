import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEmojiNeutralFill } from "react-icons/bs";
import { BsEmojiAngryFill } from "react-icons/bs";
import { BsEmojiFrownFill } from "react-icons/bs";
import { BsEmojiSmileFill } from "react-icons/bs";
import { BsEmojiLaughingFill } from "react-icons/bs";
import LoaderButton from "../../../components/loader";

function ContactForm({ formStyle }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    contact_for: [],
    rating: "",
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const ratingData = [
    {
      label: "Very Dissatisfied",
      emoji: <BsEmojiAngryFill />,
      color: "#FF4C4C",
    }, // Red
    { label: "Dissatisfied", emoji: <BsEmojiFrownFill />, color: "#FF6F61" }, // Light Red
    { label: "Neutral", emoji: <BsEmojiNeutralFill />, color: "#FFC107" }, // Yellow
    { label: "Satisfied", emoji: <BsEmojiSmileFill />, color: "#4CAF50" }, // Green
    {
      label: "Very Satisfied",
      emoji: <BsEmojiLaughingFill />,
      color: "#388E3C",
    }, // Dark Green
  ];
  const apiEndPoint = `${process.env.REACT_APP_BACKEND_URL}/api/contact-application`;

  const validateField = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "name":
        if (!value.trim()) errorMessage = "Name is required.";
        else if (value.length > 100)
          errorMessage = "Name must be less than 100 characters.";
        break;
      case "email":
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value.trim()) errorMessage = "Email address is required.";
        else if (!emailPattern.test(value))
          errorMessage = "Invalid email address.";
        else if (value.length > 200)
          errorMessage = "Email must be less than 200 characters.";
        break;
      case "phone":
        const phonePattern = /^[0-9]{4,15}$/;
        if (!value.trim()) errorMessage = "Phone number is required.";
        else if (!phonePattern.test(value))
          errorMessage = "Phone number must be between 4 and 15 digits.";
        break;
      case "subject":
        if (!value.trim()) errorMessage = "Subject is required.";
        else if (value.length > 255)
          errorMessage = "Subject must be less than 255 characters.";
        break;
      case "message":
        if (!value.trim()) errorMessage = "Message is required.";
        else if (value.length > 600)
          errorMessage = "Message must be less than 600 characters.";
        break;
      case "contact_for":
        if (value.length === 0)
          errorMessage = "Please select at least one option.";
        break;
      default:
        break;
    }

    return errorMessage;
  };

  const validateForm = () => {
    let validationErrors = {};
    let isValid = true;

    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const error = validateField(key, formData[key]);
        if (error) {
          validationErrors[key] = error;
          isValid = false;
        }
      }
    }
    if (!isChecked) {
      validationErrors.checkbox = "You must agree to the terms to proceed.";
      isValid = false;
    }
    setErrors(validationErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(apiEndPoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          toast.error(data.message);
        } else if (response.status === 400) {
          const fieldErrors = {};
          for (const fieldName in data.errors) {
            if (data.errors.hasOwnProperty(fieldName)) {
              fieldErrors[fieldName] = data.errors[fieldName][0];
            }
          }
          setErrors(fieldErrors);
        } else {
          throw new Error("Something went wrong.");
        }
      } else {
        toast.success(
          "Your message has been successfully sent. We will contact you soon."
        );

        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
          contact_for: [],
          rating: "",
        });
        setIsChecked(false);
        setRating("");
        setErrors({});
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue =
      name === "phone" ? value.replace(/\D/g, "") : value.trimStart();

    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));

    if (touched[name]) {
      const error = validateField(name, updatedValue);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedContactFor = checked
      ? [...formData.contact_for, value]
      : formData.contact_for.filter((service) => service !== value);

    setFormData((prevState) => ({
      ...prevState,
      contact_for: updatedContactFor,
    }));

    if (errors.contact_for) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact_for: "",
      }));
    }
  };

  const handlePermissionCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (errors.checkbox) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        checkbox: "",
      }));
    }
  };

  const errorStyle = {
    fontSize: "12px",
    color: "red",
    fontWeight: "900",
    marginTop: "0.25rem",
  };

  const handleEmojiClick = (index) => {
    setRating(index + 1);
    setFormData((prevState) => ({
      ...prevState,
      rating: index + 1,
    }));
  };

  return (
    <div className={formStyle}>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Your Name <span>*</span>
          </label>
          <input
            type="text"
            name="name"
            maxLength={100}
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.name && <label style={errorStyle}>{errors.name}</label>}
        </div>

        <div className="form-group">
          <label>
            Email Address <span>*</span>
          </label>
          <input
            type="email"
            name="email"
            maxLength={200}
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.email && <label style={errorStyle}>{errors.email}</label>}
        </div>

        <div className="form-group">
          <label>
            Phone Number <span>*</span>
          </label>
          <input
            type="text"
            name="phone"
            maxLength={15}
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.phone && <label style={errorStyle}>{errors.phone}</label>}
        </div>

        <div className="form-group">
          <label>
            Subject <span>*</span>
          </label>
          <input
            type="text"
            name="subject"
            maxLength={255}
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.subject && <label style={errorStyle}>{errors.subject}</label>}
        </div>

        <div className="form-group">
          <label>
            Your Message <span>*</span>
          </label>
          <textarea
            name="message"
            maxLength={600}
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.message && <label style={errorStyle}>{errors.message}</label>}
        </div>
        <div className="form-group">
          <label style={{ marginLeft: "10px" }}>I am interested in*</label>
          <div
            style={{
              marginLeft: "10px",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <div>
              <input
                type="checkbox"
                id="sdass"
                style={{ width: "15px" }}
                value="Software Development As A Service"
                checked={formData.contact_for.includes(
                  "Software Development As A Service"
                )}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="sdass">Software Development As A Service</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="taas"
                style={{ width: "15px" }}
                value="Quality Assurance As A Service"
                checked={formData.contact_for.includes(
                  "Quality Assurance As A Service"
                )}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="taas"> Quality Assurance As A Service</label>
            </div>
            <div>
              <input
                type="checkbox"
                style={{ width: "15px" }}
                id="mobileAppDev"
                value="Mobile App Development"
                checked={formData.contact_for.includes(
                  "Mobile App Development"
                )}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="mobileAppDev">Mobile App Development</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="webAppDev"
                style={{ width: "15px" }}
                value="Web App Development"
                checked={formData.contact_for.includes("Web App Development")}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="webAppDev">Web App Development</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="careers"
                value="Careers"
                style={{ width: "15px" }}
                checked={formData.contact_for.includes("Careers")}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="careers">Careers</label>
            </div>

            <div>
              <input
                type="checkbox"
                style={{ width: "15px" }}
                id="others"
                value="Others"
                checked={formData.contact_for.includes("Others")}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="others">Others</label>
            </div>
          </div>
          {errors.contact_for && (
            <label style={errorStyle}>{errors.contact_for}</label>
          )}
        </div>
        <div className="form-group">
          <label>Website Feedback</label>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginTop: "1rem",
            }}
          >
            {ratingData.map((item, index) => (
              <span
                key={index}
                style={{
                  fontSize: "2.5rem",
                  cursor: "pointer",
                  margin: "0 0.5rem",
                  color: index < rating ? item.color : "gray",
                }}
                onClick={() => handleEmojiClick(index)}
              >
                {item.emoji}
              </span>
            ))}
          </div>
          {rating > 0 && (
            <div
              style={{
                textAlign: "left",
                color: ratingData[rating - 1].color,
                fontSize: "1.2rem",
                fontWeight: "900",
                marginTop: "0.5rem",
              }}
            >
              {ratingData[rating - 1].label}
            </div>
          )}
        </div>
        <div>
          <input
            type="checkbox"
            id="consentCheckbox"
            checked={isChecked}
            onChange={handlePermissionCheckboxChange}
            // required
          />
          <label htmlFor="consentCheckbox" style={{ marginBottom: "20px" }}>
            By using this form, you consent to the storage and handling of your
            data by the website.<span>*</span>
          </label>
        </div>
        {errors.checkbox && <label style={errorStyle}>{errors.checkbox}</label>}

        <div className="form-group">
          <button
            type="submit"
            className="btn-default btn-large"
            style={{ marginTop: "15px" }}
            disabled={loading}
          >
            {loading ? <LoaderButton /> : "Submit Now"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
