// import React, { useState, useEffect } from "react";
// import BlogClassicData from "../../../data/blog/BlogList.json";
// import BlogList from "./BlogList";
// import { FaSpinner } from "react-icons/fa";
// import filters from "./Filters";
// import GetBlogList from "../../../api/blogList";

// const alldataBLog = await GetBlogList();
// const BlogProp = ({ column, StyleVarProp }) => {
//   // console.log("alldataBLog====>", alldataBLog);
//   const [getAllItems] = useState(alldataBLog);
//   const [dataVisibleCount, setDataVisibleCount] = useState(15);
//   const [dataIncrement] = useState(3);
//   const [noMorePost, setNoMorePost] = useState(false);
//   const [activeFilter, setActiveFilter] = useState("");
//   const [visibleItems, setVisibleItems] = useState([]);
//   // console.log("visibleItems=====>", visibleItems);
//   useEffect(() => {
//     setActiveFilter(filters[0].text.toLowerCase());
//     setVisibleItems(
//       getAllItems
//       //getAllItems.filter((item) => item.id <= dataVisibleCount)
//     );
//   }, []);

//   const handleLoadmorebl = (e) => {
//     e.preventDefault();
//     let tempCount = dataVisibleCount + dataIncrement;
//     if (dataVisibleCount >= getAllItems.length) {
//       setNoMorePost(true);
//     } else {
//       setDataVisibleCount(tempCount);
//       if (activeFilter === filters[0].text.toLowerCase()) {
//         setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
//       } else {
//         setVisibleItems(
//           getAllItems.filter(
//             (data) => data.text === activeFilter && data.id <= tempCount
//           )
//         );
//       }
//     }
//   };

//   return (
//     <>
//       {console.log("visibleItems====>", visibleItems)}
//       <div className="col-lg-12">
//         <div className="row row--15">
//           {visibleItems.length > 0 &&
//             visibleItems.map((item) => (
//               <div key={item.id} className={column}>
//                 <BlogList StyleVar={StyleVarProp} data={item} />
//               </div>
//             ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default BlogProp;

import React, { useState, useEffect } from "react";
import BlogList from "./BlogList";
import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";
import GetBlogList from "../../../api/blogList";

const BlogProp = ({ column, StyleVarProp }) => {
  const [getAllItems, setGetAllItems] = useState([]); // Initialize as an empty array
  const [dataVisibleCount, setDataVisibleCount] = useState(15);
  const [dataIncrement] = useState(3);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true); // New state for loading

  // Fetch blog data on component mount
  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        setLoading(true); // Start loading
        const alldataBLog = await GetBlogList();
        setGetAllItems(alldataBLog || []); // Ensure getAllItems is an array
        setActiveFilter(filters[0].text.toLowerCase());
        setVisibleItems(alldataBLog.slice(0, dataVisibleCount)); // Initial visible items
      } catch (error) {
        console.error("Failed to fetch blog data:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchBlogList();
  }, [dataVisibleCount]);

  const handleLoadmorebl = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (tempCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.text === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {/* Show loading spinner while data is being fetched */}
          {loading ? (
            <div className="col-12 text-center">
              <FaSpinner className="fa-spin" size={32} />
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {/* If no items are visible, show a message */}
              {visibleItems && visibleItems.length === 0 && (
                <div className="col-12 text-center">
                  <p>No posts found.</p>
                </div>
              )}

              {/* If there are visible items, render them */}
              {visibleItems &&
                visibleItems.length > 0 &&
                visibleItems.map((item) => (
                  <div key={item.id} className={column}>
                    <BlogList StyleVar={StyleVarProp} data={item} />
                  </div>
                ))}

              {/* Load More Button */}
              {/* {!noMorePost && visibleItems.length > 0 && (
                <div className="col-12 text-center">
                  <button onClick={handleLoadmorebl}>Load More</button>
                </div>
              )} */}

              {/* No More Posts */}
              {/* {noMorePost && visibleItems.length > 0 && (
                <div className="col-12 text-center">
                  <p>No more posts to show.</p>
                </div>
              )} */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogProp;
