import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const AdvanceTabTwo = () => {
  return (
    <div>
      <Tabs>
        <div className="row row--30">
          <div className="col-lg-8">
            <div className="advance-tab-content advance-tab-content-1">
              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src="./images/tab/tabs-03.webp" alt="" />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src="./images/tab/tabs-02.webp" alt="" />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src="./images/tab/tabs-04.webp" alt="" />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
          <div className="col-lg-4 mt_md--30 mt_sm--30">
            <div className="advance-tab-button advance-tab-button-1 right-align">
              <TabList className="tab-button-list">
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Embrace Continuous Learning.</h4>
                    <p className="description">
                      We believe in fostering a culture of continuous learning.
                      We offer opportunities to develop new skills through
                      conferences, workshops, and internal training programs.
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Your Contribution Counts</h4>
                    <p className="description">
                      We encourage innovative thinking and empower our employees
                      to contribute their ideas.
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Work-Life Balance, We Understand.</h4>
                    <p className="description">
                      We understand the importance of maintaining a healthy
                      work-life balance.
                    </p>
                  </div>
                </Tab>
              </TabList>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default AdvanceTabTwo;
