import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Data = [
  {
    countNum: "10+",
    countTitle: "Happy Customers",
    // description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
  },
  {
    countNum: "98%",
    countTitle: "Satisfaction Rate",
    // description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
  },
  {
    countNum: "20+",
    countTitle: "Completed Projects",
    // description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
  },
];
const CounterUpTwo = ({ textALign, counterStyle, column }) => {
  return (
    <div className="row">
      {Data &&
        Data.map((data, index) => (
          <div className={`${column}`} key={index}>
            <div className={`count-box ${counterStyle} ${textALign}`}>
              {/* <TrackVisibility once> */}

              <div className="count-number">
                {data.countNum && data.countNum}
              </div>
              {/* </TrackVisibility> */}
              <h5 className="title">{data.countTitle && data.countTitle}</h5>
              {/* <p className="description">{data.description}</p> */}
            </div>
          </div>
        ))}
    </div>
  );
};
export default CounterUpTwo;
