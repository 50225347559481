import React, { useEffect } from "react";

const Darkmode = () => {
  // Function to toggle theme and update session storage
  const switchTheme = () => {
    const body = document.querySelector("body");
    body.classList.toggle("light");

    // Update session storage based on the new theme
    const theme = body.classList.contains("light") ? "light" : "dark";
    localStorage.setItem("theme", theme);
  };

  // Function to apply the saved theme from session storage
  const applySavedTheme = () => {
    const savedTheme = localStorage.getItem("theme") || "dark"; // Default to 'dark'
    const body = document.querySelector("body");

    if (savedTheme === "light") {
      body.classList.add("light");
    } else {
      body.classList.remove("light");
    }
  };

  // Apply saved theme when the component mounts
  useEffect(() => {
    applySavedTheme();
  }, []);

  return (
    <button id="darkmode" onClick={switchTheme}>
      <img
        className="light-icon"
        src={`${process.env.PUBLIC_URL}/images/icons/sun-01.svg`}
        alt="Sun icon"
      />
      <img
        className="dark-icon"
        src={`${process.env.PUBLIC_URL}/images/icons/vector.svg`}
        alt="Moon icon"
      />
    </button>
  );
};

export default Darkmode;
