import React, { useState, useEffect } from "react";
import { BsBorderLeft } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { IoChevronUpOutline } from "react-icons/io5";
import { IoChevronDownOutline } from "react-icons/io5";

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 800);
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname, location.hash]);

  const handleNavigation = (path) => {
    console.log("path========>", path);
    setActiveLink(path);
    navigate(path);
    window.scrollTo(0, 0);
  };

  const liStyle = {
    padding: isMobileView ? "5px" : "",
    margin: isMobileView ? "5px" : "",
  };

  const anchorstyle = {
    padding: isMobileView ? "16px" : "",
    background: isMobileView ? "rgb(43 43 43)" : "transparent",
    borderRadius: isMobileView ? "8px" : "",
    borderBottom: "3px solid transparent",
    color: "inherit",
    textDecoration: "none",
    transition: "all 0.3s ease",
  };

  const activeStyle = {
    ...anchorstyle,
    width: "100%",
    background:
      "linear-gradient(95deg, var(--color-primary), var(--color-tertiary), var(--color-pink), var(--color-secondary))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    display: "inline-block",

    borderBottom: "3px solid transparent",
    borderImage:
      "linear-gradient(95deg, var(--color-primary), var(--color-tertiary), var(--color-pink), var(--color-secondary)) 1", // Gradient border
  };

  const activeStyleMobileView = {
    ...anchorstyle,
    width: "100%",
    color: "transparent",
    font: "bold",
    background:
      "linear-gradient(95deg, var(--color-primary), var(--color-tertiary), var(--color-pink), var(--color-secondary))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    display: "inline-block",
    border: "3px solid transparent",
    borderImage:
      "linear-gradient(95deg, var(--color-primary), var(--color-tertiary), var(--color-pink), var(--color-secondary)) 1", // Gradient border
  };

  return (
    <ul className="mainmenu" key={`appProduct-${location.pathname}`}>
      <li className="has-droupdown" style={liStyle}>
        <a
          className="darkthime_anchor_tag light_thim_anchor"
          style={{
            ...(activeLink.startsWith("/our-services")
              ? activeStyle
              : anchorstyle),
            ...(isMobileView && {
              borderTop: "3px solid transparent !important",
              borderRight: "3px solid transparent !important",
              BsBorderLeft: "3px solid transparent !important",
            }),
          }}
          href="/our-services"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation("/our-services");
          }}
        >
          Our Services
        </a>
        <ul className="submenu">
          <li>
            <a
              href="#1"
              onClick={(e) => {
                handleNavigation("/our-services/#1");
              }}
            >
              Software Dev as a Service (SDaaS)
            </a>
          </li>
          <li>
            <a
              href="#2"
              onClick={(e) => {
                handleNavigation("/our-services/#2");
              }}
            >
              Testing as a Service (TaaS)
            </a>
          </li>
          <li>
            <a
              href="#3"
              onClick={(e) => {
                handleNavigation("/our-services/#3");
              }}
            >
              Mobile App Development
            </a>
          </li>
          <li>
            <a
              href="#4"
              onClick={(e) => {
                handleNavigation("/our-services/#4");
              }}
            >
              Web App Development
            </a>
          </li>
        </ul>
      </li>
      <li className="has-droupdown" style={liStyle}>
        <a
          className="darkthime_anchor_tag light_thim_anchor"
          style={
            activeLink.startsWith("/our-products") ? activeStyle : anchorstyle
          }
          href="/our-products"
          onClick={(e) => {
            e.preventDefault();
            handleNavigation("/our-products");
          }}
        >
          Our Products
          {/* <span style={{ float: "right" }}>
            <IoChevronUpOutline />
            <IoChevronDownOutline />
          </span> */}
        </a>

        <ul className="submenu">
          <li>
            <a
              href="#BLENDIN"
              onClick={(e) => {
                handleNavigation("/our-products/#BLENDIN");
              }}
            >
              BLENDIN
            </a>
          </li>
          <li>
            <a
              href="#MYT"
              onClick={(e) => {
                handleNavigation("/our-products/#MYT");
              }}
            >
              MYT
            </a>
          </li>
          <li>
            <a
              href="#MangOO"
              onClick={(e) => {
                handleNavigation("/our-products/#MangOO");
              }}
            >
              MangOO
            </a>
          </li>
          <li>
            <a
              href="#Vinty"
              onClick={(e) => {
                handleNavigation("/our-products/#Vinty");
              }}
            >
              Vinty
            </a>
          </li>
          <li>
            <a
              href="#JOYREJOY"
              onClick={(e) => {
                handleNavigation("/our-products/#JOYREJOY");
              }}
            >
              JOYREJOY
            </a>
          </li>
        </ul>
      </li>
      <li style={liStyle}>
        {isMobileView ? (
          <>
            <a
              className="darkthime_anchor_tag light_thim_anchor"
              style={
                activeLink === "/who-we-are"
                  ? activeStyleMobileView
                  : anchorstyle
              }
              href="/who-we-are"
            >
              Who We Are
            </a>
          </>
        ) : (
          <>
            <a
              className="darkthime_anchor_tag light_thim_anchor"
              style={activeLink === "/who-we-are" ? activeStyle : anchorstyle}
              href="/who-we-are"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("/who-we-are");
              }}
            >
              Who We Are
            </a>
          </>
        )}
      </li>
      <li style={liStyle}>
        {isMobileView ? (
          <>
            <a
              href="/blogs"
              className="darkthime_anchor_tag light_thim_anchor"
              style={
                activeLink.startsWith("/blog")
                  ? activeStyleMobileView
                  : anchorstyle
              }
            >
              Blogs
            </a>
          </>
        ) : (
          <>
            {" "}
            <a
              href="/blogs"
              className="darkthime_anchor_tag light_thim_anchor"
              style={activeLink.startsWith("/blog") ? activeStyle : anchorstyle}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("/blogs");
              }}
            >
              Blogs
            </a>
          </>
        )}
      </li>
      <li style={liStyle}>
        {isMobileView ? (
          <>
            <a
              href="/careers"
              className="darkthime_anchor_tag"
              style={
                activeLink.startsWith("/careers")
                  ? activeStyleMobileView
                  : anchorstyle
              }
            >
              Careers
            </a>
          </>
        ) : (
          <>
            <a
              href="/careers"
              className="darkthime_anchor_tag"
              style={
                activeLink.startsWith("/careers") ? activeStyle : anchorstyle
              }
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("/careers");
              }}
            >
              Careers
            </a>
          </>
        )}
      </li>
    </ul>
  );
};

export default Nav;
