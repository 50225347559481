import React, { useEffect, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import wepProductData from "../../../data/product/webProduct.json";

const WebProductDetails = ({ id }) => {
  return (
    <div
      className="rn-timeline-wrapper timeline-style-one position-relative"
      // ref={containerRef}
    >
      {/* Start Single Timeline  */}
      {wepProductData &&
        wepProductData.map((data, index) => (
          <div
            className="single-timeline mt--50"
            key={index}
            id={`${data.title}`}
          >
            <div className="timeline-dot">
              <div className="time-line-circle"></div>
            </div>
            <div className="single-content">
              <div className="inner">
                <div className="row row--30 align-items-center">
                  <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                    <div className="content">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <span className="date-of-timeline b1">
                          {data.subTitle && data.subTitle}
                        </span>
                      </ScrollAnimation>

                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <h2 className="title">{data.title && data.title}</h2>
                      </ScrollAnimation>

                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <p className="description b1 color-gray">
                          {data.description && data.description}
                        </p>
                      </ScrollAnimation>

                      <div className="row row--30">
                        {data.details &&
                          data.details.map((detail, index) => (
                            <div
                              className="col-lg-6 col-md-6 col-12"
                              key={index}
                            >
                              <div className="working-list">
                                <ScrollAnimation
                                  animateIn="fadeInUp"
                                  animateOut="fadeInOut"
                                  animateOnce={true}
                                >
                                  <h5 className="working-title b1">
                                    {detail.stepTitle && detail.stepTitle}
                                  </h5>
                                </ScrollAnimation>

                                <ScrollAnimation
                                  animateIn="fadeInUp"
                                  animateOut="fadeInOut"
                                  animateOnce={true}
                                >
                                  <p className="color-gray">
                                    {detail.stepDescription &&
                                      detail.stepDescription}
                                  </p>
                                </ScrollAnimation>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="read-morebtn">
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                        >
                          <a
                            className="btn-default btn-large round"
                            target="_blank"
                            href={data.buyer}
                          >
                            <span>Buyer</span>
                          </a>{" "}
                          <hr></hr>
                          <a
                            className="btn-default btn-large round"
                            target="_blank"
                            href={data.seller}
                          >
                            <span>Seller</span>
                          </a>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                  <div className="order-1 order-lg-2 col-lg-6">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src={data.image}
                        alt="Corporate React Template"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {/* End Single Timeline  */}
    </div>
  );
};

export default WebProductDetails;
