import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Tabs } from "react-tabs";
import { PiUserFocusThin } from "react-icons/pi";
import { PiScalesThin } from "react-icons/pi";
import { TbUserHeart } from "react-icons/tb";
import { PiArrowUpRightThin } from "react-icons/pi";
import { PiShootingStarThin } from "react-icons/pi";
import { PiUsersFourThin } from "react-icons/pi";
const AdvanceTabThree = ({ textAlign, serviceStyle }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ServiceList = [
    {
      icon: <PiUserFocusThin />,
      title: "CUSTOMER CENTRIC",
      color: "red",
      description:
        "More than transactions; it’s a positive journey. Our customer-centric approach crafts experiences that linger before and after the sale, fostering loyalty and driving repeat business. In our world, customers are not just transactions; they are the heartbeat of our business.",
    },
    {
      icon: <PiUsersFourThin />,
      title: "TEAM COLLABORATION",
      color: "green",
      description:
        "Team Collaboration is at the heart of our values, reflecting our commitment to working together in a cohesive and supportive environment. We foster open communication, encourage diverse perspectives, and value each team member's contributions. ",
    },
    {
      icon: <PiShootingStarThin />,
      title: "EXCELLENCE",
      color: "#F79424",
      description:
        "We focus on delivering top-quality results with clear and precise work. We make complex problems simpler and concentrate on what really matters. By sticking to high standards and straightforward solutions, we ensure our work always meets and exceeds expectations.",
    },
    {
      icon: <PiArrowUpRightThin />,
      title: "BOTTOM-UP CULTURE",
      description:
        "Our people wield extraordinary influence through the magic of participation, where small actions create seismic shifts.  We value existence and voice of all.  We are committed to impactful, bottom-up change.",
    },
    {
      icon: <TbUserHeart />,
      title: "HONESTY & TRANSPARENCY",
      color: "blue",
      description:
        "Secret sauce? We’re an open book. From Woohoo! Victories to Oh! Fallbacks, our team is on the same page. We believe in a shared narrative, keeping everyone tuned into the symphony of success and the rhythm of setbacks.",
    },
    {
      icon: <PiScalesThin />,
      title: "ETHICS",
      color: "#E41175",
      description:
        "Big work, big responsibility. A solid internal culture is our compass, guiding us through the responsibilities of a sizable team. Sustainability in responsibility is our ethos, ensuring each step is rooted in ethical strength.",
    },
  ];
  return (
    <div>
      <Tabs>
        <div className={isLargeScreen ? "row row--30" : ""}>
          <div className={isLargeScreen ? "row row--15 service-wrapper" : ""}>
            {ServiceList.map((val, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className={`service ${serviceStyle} ${textAlign}`}>
                    <div
                      style={{
                        fontSize: "45px",
                        color: val.color,
                        fontWeight: "900",
                      }}
                      className="icon"
                    >
                      {val.icon}
                    </div>
                    <div className="content">
                      <h4
                        className="title w-600"
                        dangerouslySetInnerHTML={{ __html: val.title }}
                      ></h4>
                      <p
                        className="description b1 color-gray mb--0"
                        dangerouslySetInnerHTML={{ __html: val.description }}
                      ></p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            ))}
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default AdvanceTabThree;
