import React, { useEffect, useState } from "react";
import Job from "./job";
import SectionTitle from "../../../elements/sectionTitle/SectionTitle";
import { toast } from "react-toastify";

const Jobs = () => {
  const apiEndPoint = `${process.env.REACT_APP_BACKEND_URL}/api/jobs`;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(apiEndPoint);

        if (response) {
          setLoading(false);
        }

        const jsonData = await response.json();

        if (!response.ok) {
          // toast.error(jsonData.message);
        }

        setData(jsonData.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  {
    loading && (
      <div className="container">
        {" "}
        <h4>Loading Jobs...</h4>{" "}
      </div>
    );
  }
  return (
    <>
      <>
        <div className="row">
          <div className="col-lg-12 mb--40">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle={!data ? "Job Openings" : "We Are Hiring"}
              title={
                !data
                  ? "Great talent is always in demand. Submit your resume for future opportunities."
                  : "Current Job Openings"
              }
              description=""
            />
          </div>
        </div>
        {data && (
          <div className="container">
            <div style={{ padding: "5px" }}>
              {data.map((job) => (
                <Job id={job.id} title={job.title} subtitle={job.experience} />
              ))}
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default Jobs;
