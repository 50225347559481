import React from "react";
import Layout from "../../common/Layout";
const NoRoutesMatched = () => {
  return (
    <Layout>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                <h1>404</h1>
                <h2 className="title">Page not found.</h2>

                <div className="view-more-button">
                  <a className="btn-default" href="/">
                    Go To Home Page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NoRoutesMatched;
