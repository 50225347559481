import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderButton from "../../../components/loader";

function ApplyNowForm({ jobId }) {
  const [formData, setFormData] = useState({
    job_id: jobId,
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    current_ctc: "",
    expected_ctc: "",
    years_of_experience: "",
    notice_period: "",
    current_location: "",
    tenure_current_org: "",
    cover_letter: "",
    resume: null,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resumeFileName, setResumeFileName] = useState("");

  const apiEndPoint = `${process.env.REACT_APP_BACKEND_URL}/api/job-application`;

  const validateField = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "f_name":
        if (!value.trim()) errorMessage = "First name is required.";
        break;
      case "l_name":
        if (!value.trim()) errorMessage = "Last name is required.";
        break;
      case "email":
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value.trim()) {
          errorMessage = "Email address is required.";
        } else if (!emailPattern.test(value.trim())) {
          errorMessage = "Please enter a valid email address.";
        }
        break;
      case "phone":
        const phonePattern = /^[0-9]{10}$/;
        if (!value.trim()) {
          errorMessage = "Phone number is required.";
        } else if (!phonePattern.test(value.trim())) {
          errorMessage = "Please enter a valid phone number.";
        }
        break;
      case "current_ctc":
      case "expected_ctc":
      case "years_of_experience":
      case "notice_period":
      case "current_location":
      case "tenure_current_org":
        if (!value.trim())
          errorMessage = `${name.replace("_", " ")} is required.`;
        break;
      case "cover_letter":
        if (!value.trim()) {
          errorMessage = "Cover letter is required.";
        } else if (value.length > 7000) {
          errorMessage = "Cover letter length must be below 7,000 characters.";
        }
        break;
      case "resume":
        const MAX_FILE_SIZE_MB = 2;
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
        if (!value) {
          errorMessage = "Resume is required.";
        } else if (value.size > MAX_FILE_SIZE_BYTES) {
          errorMessage = `Resume must be less than ${MAX_FILE_SIZE_MB} MB.`;
        }
        break;
      default:
        break;
    }

    return errorMessage;
  };

  const validateForm = () => {
    let validationErrors = {};
    let isValid = true;

    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const error = validateField(key, formData[key]);
        if (error) {
          validationErrors[key] = error;
          isValid = false;
        }
      }
    }

    if (!isChecked) {
      validationErrors.checkbox = "You must agree to the terms to proceed.";
      isValid = false;
    }

    setErrors(validationErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await fetch(apiEndPoint, {
        method: "POST",
        body: formDataToSend,
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          toast.error(data.message);
        } else if (response.status === 400) {
          const fieldErrors = {};
          for (const fieldName in data.errors) {
            if (data.errors.hasOwnProperty(fieldName)) {
              fieldErrors[fieldName] = data.errors[fieldName][0];
            }
          }
          setErrors(fieldErrors);
        } else {
          throw new Error("Something went wrong.");
        }
      } else {
        toast.success(
          "Your form has been successfully sent. We will update you soon."
        );

        setFormData({
          job_id: jobId,
          f_name: "",
          l_name: "",
          email: "",
          phone: "",
          current_ctc: "",
          expected_ctc: "",
          years_of_experience: "",
          notice_period: "",
          current_location: "",
          tenure_current_org: "",
          cover_letter: "",
          resume: null,
        });
        setIsChecked(false);
        setResumeFileName("");
        setErrors({});
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const sanitizeNumericInput = (inputValue) => inputValue.replace(/\D/g, "");

    const updatedValue =
      name === "phone" ||
      name === "current_ctc" ||
      name === "expected_ctc" ||
      name === "years_of_experience"
        ? sanitizeNumericInput(value)
        : value.trimStart();

    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));

    if (touched[name]) {
      const error = validateField(name, updatedValue);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setFormData((prevState) => ({
          ...prevState,
          resume: file,
        }));
        setResumeFileName(file.name);
        setErrors((prevErrors) => ({
          ...prevErrors,
          resume: "",
        }));
      } else {
        alert("Please select a PDF file for the resume.");
        setErrors((prevErrors) => ({
          ...prevErrors,
          resume: "Resume must be a PDF file.",
        }));
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (errors.checkbox) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        checkbox: "",
      }));
    }
  };

  const errorStyle = {
    fontSize: "12px",
    color: "red",
    fontWeight: "900",
    marginTop: "0.25rem",
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            First Name <span>*</span>
          </label>
          <input
            type="text"
            name="f_name"
            maxLength={50}
            placeholder="Your First Name"
            value={formData.f_name}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            required
          />
          {errors.f_name && <label style={errorStyle}>{errors.f_name}</label>}
        </div>

        <div className="form-group">
          <label>
            Last Name <span>*</span>
          </label>
          <input
            type="text"
            name="l_name"
            maxLength={50}
            placeholder="Your Last Name"
            value={formData.l_name}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            required
          />
          {errors.l_name && <label style={errorStyle}>{errors.l_name}</label>}
        </div>

        <div className="form-group">
          <label>
            Email Address <span>*</span>
          </label>
          <input
            type="email"
            name="email"
            maxLength={150}
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            required
          />
          {errors.email && <label style={errorStyle}>{errors.email}</label>}
        </div>

        <div className="form-group">
          <label>
            Phone Number <span>*</span>
          </label>
          <input
            type="text"
            name="phone"
            maxLength={10}
            placeholder="Phone Number"
            value={formData.phone}
            pattern="[0-9]{10}"
            title="Please enter a 10-digit phone number"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            required
          />
          {errors.phone && <label style={errorStyle}>{errors.phone}</label>}
        </div>

        <div className="form-group">
          <label>
            Current CTC <span>* </span>
          </label>
          <input
            type="text"
            name="current_ctc"
            maxLength={8}
            placeholder="Current CTC (e.g., 0 for fresher)"
            value={formData.current_ctc}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.current_ctc && (
            <label style={errorStyle}>{errors.current_ctc}</label>
          )}
        </div>

        <div className="form-group">
          <label>
            Expected CTC <span>*</span>
          </label>
          <input
            type="text"
            name="expected_ctc"
            maxLength={8}
            placeholder="Expected CTC (e.g., 60000)"
            value={formData.expected_ctc}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.expected_ctc && (
            <label style={errorStyle}>{errors.expected_ctc}</label>
          )}
        </div>

        <div className="form-group">
          <label>
            Years of Experience <span>*</span>
          </label>
          <input
            type="text"
            name="years_of_experience"
            maxLength={2}
            placeholder="Years of Experience"
            value={formData.years_of_experience}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.years_of_experience && (
            <label style={errorStyle}>{errors.years_of_experience}</label>
          )}
        </div>

        <div className="form-group">
          <label>
            Notice Period <span>* (in months)</span>
          </label>
          <input
            type="text"
            name="notice_period"
            maxLength={50}
            placeholder="Notice Period (in months)"
            value={formData.notice_period}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.notice_period && (
            <label style={errorStyle}>{errors.notice_period}</label>
          )}
        </div>

        <div className="form-group">
          <label>
            Current Location <span>*</span>
          </label>
          <input
            type="text"
            name="current_location"
            maxLength={150}
            placeholder="Current Location"
            value={formData.current_location}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.current_location && (
            <label style={errorStyle}>{errors.current_location}</label>
          )}
        </div>

        <div className="form-group">
          <label>
            Work experience at Current Organization <span>*</span>
          </label>
          <input
            type="text"
            name="tenure_current_org"
            maxLength={100}
            placeholder="Working at Current Organization (in Years)"
            value={formData.tenure_current_org}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.tenure_current_org && (
            <label style={errorStyle}>{errors.tenure_current_org}</label>
          )}
        </div>

        <div className="form-group">
          <label>
            Coverletter <span>*</span>
          </label>
          <textarea
            name="cover_letter"
            placeholder="Coverletter"
            maxLength={7000}
            value={formData.cover_letter}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.cover_letter && (
            <label style={errorStyle}>{errors.cover_letter}</label>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="resume">Upload Resume (PDF only)*</label>
          <label className="custom-file-label" htmlFor="resume-upload">
            {resumeFileName ? resumeFileName : "Choose File"}
          </label>
          <input
            type="file"
            accept="application/pdf"
            name="resume"
            id="resume-upload"
            className="custom-file-input"
            onChange={handleResumeChange}
          />
          {errors.resume && <label style={errorStyle}>{errors.resume}</label>}
        </div>

        <div>
          <input
            type="checkbox"
            id="consentCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            // required
          />
          <label htmlFor="consentCheckbox">
            By using this form, you consent to the storage and handling of your
            data by the website.<span>*</span>
          </label>
        </div>
        {errors.checkbox && <label style={errorStyle}>{errors.checkbox}</label>}
        <div className="form-group">
          <button
            style={{ width: "200px" }}
            disabled={loading}
            className="btn-default btn-large"
            type="submit"
          >
            {loading ? <LoaderButton /> : "Submit Now"}
          </button>
        </div>
      </form>
    </>
  );
}

export default ApplyNowForm;
