// import React from 'react'
// import { Accordion, Card, Button } from 'react-bootstrap';

// const AccordionOne = ({ customStyle }) => {
//     return (
//         <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
//             <Card>
//                 <Card.Header>
//                     <Accordion.Toggle as={Button} variant="link" eventKey="0">
//                         What are your business hours? Are weekends off?
//                     </Accordion.Toggle>
//                 </Card.Header>
//                 <Accordion.Collapse eventKey="0">
//                     <Card.Body>9:00 AM to 7:00 PM (Weekdays). Yes, Saturday and Sunday are off.</Card.Body>
//                 </Accordion.Collapse>
//             </Card>

//             <Card>
//                 <Card.Header>
//                     <Accordion.Toggle as={Button} variant="link" eventKey="1">
//                         What is the company culture like?
//                     </Accordion.Toggle>
//                 </Card.Header>
//                 <Accordion.Collapse eventKey="1">
//                     <Card.Body>We foster a collaborative and innovative work environment where everyone's contribution is valued.</Card.Body>
//                 </Accordion.Collapse>
//             </Card>

//             <Card>
//                 <Card.Header>
//                     <Accordion.Toggle as={Button} variant="link" eventKey="2">
//                         What is the interview process like?
//                     </Accordion.Toggle>
//                 </Card.Header>
//                 <Accordion.Collapse eventKey="2">
//                     <Card.Body>The interview process typically involves a screening call, followed by technical and behavioral interviews.</Card.Body>
//                 </Accordion.Collapse>
//             </Card>

//             <Card>
//                 <Card.Header>
//                     <Accordion.Toggle as={Button} variant="link" eventKey="3">
//                         Is the company competitive in terms of salary and compensation?
//                     </Accordion.Toggle>
//                 </Card.Header>
//                 <Accordion.Collapse eventKey="3">
//                     <Card.Body>We offer competitive salaries and compensation packages that are tailored to experience and skillset.</Card.Body>
//                 </Accordion.Collapse>
//             </Card>

//         </Accordion>
//     )
// }
// export default AccordionOne

import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./AccordionOne.css"; // Import custom CSS file

const AccordionOne = ({ customStyle }) => {
  const [activeKey, setActiveKey] = useState("");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      activeKey={activeKey}
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="0"
            onClick={() => handleToggle("0")}
            className="accordion-toggle"
            style={{ display: "flex" }}
          >
            <span className="accordion-text">
              What are your business hours? Are weekends off?
            </span>
            <span className="accordion-icon">
              {activeKey === "0" ? <FaMinus /> : <FaPlus />}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            9:00 AM to 7:00 PM (Weekdays). Yes, Saturday and Sunday are off.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="1"
            onClick={() => handleToggle("1")}
            className="accordion-toggle"
            style={{ display: "flex" }}
          >
            <span className="accordion-text">
              What is the company culture like?
            </span>
            <span className="accordion-icon">
              {activeKey === "1" ? <FaMinus /> : <FaPlus />}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            We foster a collaborative and innovative work environment where
            everyone's contribution is valued.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="2"
            onClick={() => handleToggle("2")}
            className="accordion-toggle"
            style={{ display: "flex" }}
          >
            <span className="accordion-text">
              What is the interview process like?
            </span>
            <span className="accordion-icon">
              {activeKey === "2" ? <FaMinus /> : <FaPlus />}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            The interview process typically involves a screening call, followed
            by technical and behavioral interviews.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="3"
            onClick={() => handleToggle("3")}
            className="accordion-toggle"
            style={{ display: "flex" }}
          >
            <span className="accordion-text">
              Is the company competitive in terms of salary and compensation?
            </span>
            <span className="accordion-icon">
              {activeKey === "3" ? <FaMinus /> : <FaPlus />}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            We offer competitive salaries and compensation packages that are
            tailored to experience and skillset.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AccordionOne;
