import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ServiceData from "../../data/service/ServiceList.json";

// image, title, description1, details, description2
const LeftService = ({ id }) => {
  const selectedService = ServiceData.find((service) => service.id == id);
  if (selectedService === null) {
    selectedService = ServiceData.find((service) => service.id == 1);
  }

  const { title, image, description_1, description_2, details } =
    selectedService;

  const isWideScreen = window.matchMedia("(min-width: 800px)").matches;

  const titleStyle = {
    marginTop: isWideScreen ? "180px" : "10px",
  };
  return (
    <div className="rwt-about-area">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img src={image} alt="About Images" width="543" height="652" />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title" style={titleStyle}>
                <h2 className="title">
                  {title && title} <br />
                </h2>
                <p className="description b1">
                  {description_1 && description_1}
                </p>
                {details && (
                  <>
                    <h4>{details.heading && details.heading}</h4>
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <ul className="split-list">
                        {details.points &&
                          details.points.map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                      </ul>
                    </ScrollAnimation>
                  </>
                )}
              </div>
            </div>
          </div>
          <p className="description b1">{description_2 && description_2}</p>
        </div>
      </div>
    </div>
  );
};

export default LeftService;
