import { useEffect, useState } from "react";

const TechStack = ({ brandStyle }) => {
  const [activeTab, setActiveTab] = useState("Frontend");

  const frontendList = [
    {
      image: "./images/techStack/html.webp",
      name: "HTML",
    },
    {
      image: "./images/techStack/css.webp",
      name: "CSS",
    },
    {
      image: "./images/techStack/javascript.webp",
      name: "Javascript",
    },
    {
      image: "./images/techStack/Typescript.webp",
      name: "Typescript",
    },
    {
      image: "./images/techStack/UIUX.webp",
      name: "UI/UX",
    },
    {
      image: "./images/techStack/next.webp",
      name: "Next",
    },
    {
      image: "./images/techStack/react.webp",
      name: "React",
    },
    {
      image: "./images/techStack/phplogo.webp",
      name: "PHP",
    },
    {
      image: "./images/techStack/wordpress.webp",
      name: "Wordpress",
    },
    {
      image: "./images/techStack/Bootstrap.webp",
      name: "Bootstrap",
    },
    {
      image: "./images/techStack/Tailwind-CSS.webp",
      name: "Tailwind CSS",
    },
    {
      image: "./images/techStack/Material-UI.webp",
      name: "Material-UI",
    },
  ];

  const BackendList = [
    {
      image: "./images/techStack/java.webp",
      name: "Java",
    },
    {
      image: "./images/techStack/spring.webp",
      name: "Spring",
    },
    {
      image: "./images/techStack/Hibernate.webp",
      name: "Hibernate",
    },
    {
      image: "./images/techStack/spring-boot.webp",
      name: "Spring Boot",
    },
    {
      image: "./images/techStack/python.webp",
      name: "Python",
    },
    {
      image: "./images/techStack/phplogo.webp",
      name: "PHP",
    },
    {
      image: "./images/techStack/Laravel.webp",
      name: "Laravel",
    },
    {
      image: "./images/techStack/node.webp",
      name: "Node",
    },
    {
      image: "./images/techStack/express.webp",
      name: "Express",
    },
    {
      image: "./images/techStack/ASP.net.webp",
      name: "ASP.Net",
    },
  ];
  const TestingAndQAList = [
    {
      image: "./images/techStack/playwrite.webp",
      name: "Playwright",
    },
    {
      image: "./images/techStack/Cypress_new.webp",
      name: "Cypress",
    },
    {
      image: "./images/techStack/codecept.webp",
      name: "Codecept",
    },
    {
      image: "./images/techStack/Selenium.webp",
      name: "Selenium",
    },
    {
      image: "./images/techStack/Appium.webp",
      name: "Appium",
    },
    {
      image: "./images/techStack/robot_new.webp",
      name: "Robot",
    },
    {
      image: "./images/techStack/jmeter.webp",
      name: "JMeter",
    },
    {
      image: "./images/techStack/PyTest.webp",
      name: "PyTest",
    },
    {
      image: "./images/techStack/TestNG.webp",
      name: "TestNG",
    },
    {
      image: "./images/techStack/mocha.webp",
      name: "Mocha",
    },
    {
      image: "./images/techStack/BurpSuite.webp",
      name: "BurpSuite",
    },
    {
      image: "./images/techStack/Confluence.webp",
      name: "Confluence",
    },
    {
      image: "./images/techStack/jira.webp",
      name: "Jira",
    },
    {
      image: "./images/techStack/maven.webp",
      name: "Maven",
    },
    {
      image: "./images/techStack/Kibana.webp",
      name: "Kibana",
    },
    {
      image: "./images/techStack/postman.webp",
      name: "Postman",
    },
  ];
  const cloudList = [
    {
      image: "./images/techStack/Aws.webp",
      name: "Aws",
    },
    {
      image: "./images/techStack/Azure.webp",
      name: "Azure",
    },
    {
      image: "./images/techStack/git.webp",
      name: "Git",
    },

    {
      image: "./images/techStack/Docker.webp",
      name: "Docker",
    },
    {
      image: "./images/techStack/Bitbucket.webp",
      name: "Bitbucket",
    },
    {
      image: "./images/techStack/Jenkins.webp",
      name: "Jenkins",
    },
    {
      image: "./images/techStack/GitLab.webp",
      name: "GitLab",
    },
  ];
  const Mobility = [
    {
      image: "./images/techStack/Android.webp",
      name: "Android",
    },
    {
      image: "./images/techStack/dart.webp",
      name: "Dart",
    },
    {
      image: "./images/techStack/Kotlin.webp",
      name: "Kotlin",
    },
    {
      image: "./images/techStack/Flutter.webp",
      name: "Flutter",
    },
  ];
  const DatabaseList = [
    {
      image: "./images/techStack/PostgreSQL.webp",
      name: "PostgreSQL",
    },
    {
      image: "./images/techStack/redis.webp",
      name: "Redis",
    },
    {
      image: "./images/techStack/DynamoDB.webp",
      name: "DynamoDB",
    },
    {
      image: "./images/techStack/Cassandra.webp",
      name: "Cassandra",
    },
    {
      image: "./images/techStack/Firebase.webp",
      name: "Firebase",
    },
    {
      image: "./images/techStack/mysql.webp",
      name: "MySQL",
    },
    {
      image: "./images/techStack/ElasticSearch.webp",
      name: "Elastic Search",
    },
    {
      image: "./images/techStack/SQLite.webp",
      name: "SQLite",
    },
    {
      image: "./images/techStack/mongo.webp",
      name: "MongoDB",
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
  };

  useEffect(() => {
    const allImages = [
      ...frontendList,
      ...BackendList,
      ...TestingAndQAList,
      ...cloudList,
      ...Mobility,
      ...DatabaseList,
    ];

    allImages.forEach((item) => preloadImage(item.image));
  }, [
    frontendList,
    BackendList,
    TestingAndQAList,
    cloudList,
    Mobility,
    DatabaseList,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const thresholdWidth = 1200;

  const renderContent = () => {
    const listMap = {
      Frontend: frontendList,
      Backend: BackendList,
      "Testing & QA": TestingAndQAList,
      "Cloud & DevOps": cloudList,
      Mobility: Mobility,
      Databases: DatabaseList,
    };

    return (
      <ul className={`brand-list ${brandStyle}`} style={{ gap: "18px" }}>
        {listMap[activeTab].map((data, index) => (
          <li
            className="list_techstack_element"
            key={index}
            style={{
              flexBasis: windowWidth > thresholdWidth ? "13%" : "",
              backgroundColor: "#1e1e1e",
              borderRadius: "10px",
            }}
          >
            <img
              style={{ height: "85px" }}
              src={`${data.image}`}
              alt="Tech-stack Image"
              draggable="false"
            />
            <div style={{ fontSize: "14px" }}>{data.name}</div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
            {[
              "Frontend",
              "Backend",
              "Mobility",
              "Cloud & DevOps",
              "Databases",
              "Testing & QA",
            ].map((tab) => (
              <li className="list-item" key={tab}>
                <button
                  onClick={() => setActiveTab(tab)}
                  className={activeTab === tab ? "current" : ""}
                >
                  {tab}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mt--40">{renderContent()}</div>
      </div>
    </>
  );
};

export default TechStack;

// import { useEffect, useState } from "react";

// const TechStack = ({ brandStyle }) => {
//   const [activeTab, setActiveTab] = useState("Frontend");
//   const [imagesLoaded, setImagesLoaded] = useState(false); // Track if all images are loaded

//   const frontendList = [
//     { image: "./images/techStack/html.webp", name: "HTML" },
//     { image: "./images/techStack/css.webp", name: "CSS" },
//     { image: "./images/techStack/javascript.webp", name: "Javascript" },
//     { image: "./images/techStack/Typescript.webp", name: "Typescript" },
//     { image: "./images/techStack/UIUX.webp", name: "UI/UX" },
//     { image: "./images/techStack/next.webp", name: "Next" },
//     { image: "./images/techStack/react.webp", name: "React" },
//     { image: "./images/techStack/phplogo.webp", name: "PHP" },
//     { image: "./images/techStack/wordpress.webp", name: "Wordpress" },
//     { image: "./images/techStack/Bootstrap.webp", name: "Bootstrap" },
//     { image: "./images/techStack/Tailwind-CSS.webp", name: "Tailwind CSS" },
//     { image: "./images/techStack/Material-UI.webp", name: "Material-UI" },
//   ];

//   const BackendList = [
//     { image: "./images/techStack/java.webp", name: "Java" },
//     { image: "./images/techStack/spring.webp", name: "Spring" },
//     { image: "./images/techStack/Hibernate.webp", name: "Hibernate" },
//     { image: "./images/techStack/spring-boot.webp", name: "Spring Boot" },
//     { image: "./images/techStack/python.webp", name: "Python" },
//     { image: "./images/techStack/phplogo.webp", name: "PHP" },
//     { image: "./images/techStack/Laravel.webp", name: "Laravel" },
//     { image: "./images/techStack/node.webp", name: "Node" },
//     { image: "./images/techStack/express.webp", name: "Express" },
//     { image: "./images/techStack/ASP.net.webp", name: "ASP.Net" },
//   ];

//   const TestingAndQAList = [
//     { image: "./images/techStack/playwrite.webp", name: "Playwright" },
//     { image: "./images/techStack/Cypress_new.webp", name: "Cypress" },
//     { image: "./images/techStack/codecept.webp", name: "Codecept" },
//     { image: "./images/techStack/Selenium.webp", name: "Selenium" },
//     { image: "./images/techStack/Appium.webp", name: "Appium" },
//     { image: "./images/techStack/robot_new.webp", name: "Robot" },
//     { image: "./images/techStack/jmeter.webp", name: "JMeter" },
//     { image: "./images/techStack/PyTest.webp", name: "PyTest" },
//     { image: "./images/techStack/TestNG.webp", name: "TestNG" },
//     { image: "./images/techStack/mocha.webp", name: "Mocha" },
//     { image: "./images/techStack/BurpSuite.webp", name: "BurpSuite" },
//     { image: "./images/techStack/Confluence.webp", name: "Confluence" },
//     { image: "./images/techStack/jira.webp", name: "Jira" },
//     { image: "./images/techStack/maven.webp", name: "Maven" },
//     { image: "./images/techStack/Kibana.webp", name: "Kibana" },
//     { image: "./images/techStack/postman.webp", name: "Postman" },
//   ];

//   const cloudList = [
//     { image: "./images/techStack/Aws.webp", name: "Aws" },
//     { image: "./images/techStack/Azure.webp", name: "Azure" },
//     { image: "./images/techStack/git.webp", name: "Git" },
//     { image: "./images/techStack/Docker.webp", name: "Docker" },
//     { image: "./images/techStack/Bitbucket.webp", name: "Bitbucket" },
//     { image: "./images/techStack/Jenkins.webp", name: "Jenkins" },
//     { image: "./images/techStack/GitLab.webp", name: "GitLab" },
//   ];

//   const Mobility = [
//     { image: "./images/techStack/Android.webp", name: "Android" },
//     { image: "./images/techStack/dart.webp", name: "Dart" },
//     { image: "./images/techStack/Kotlin.webp", name: "Kotlin" },
//     { image: "./images/techStack/Flutter.webp", name: "Flutter" },
//   ];

//   const DatabaseList = [
//     { image: "./images/techStack/PostgreSQL.webp", name: "PostgreSQL" },
//     { image: "./images/techStack/redis.webp", name: "Redis" },
//     { image: "./images/techStack/DynamoDB.webp", name: "DynamoDB" },
//     { image: "./images/techStack/Cassandra.webp", name: "Cassandra" },
//     { image: "./images/techStack/Firebase.webp", name: "Firebase" },
//     { image: "./images/techStack/mysql.webp", name: "MySQL" },
//     { image: "./images/techStack/ElasticSearch.webp", name: "Elastic Search" },
//     { image: "./images/techStack/SQLite.webp", name: "SQLite" },
//     { image: "./images/techStack/mongo.webp", name: "MongoDB" },
//   ];

//   const listMap = {
//     Frontend: frontendList,
//     Backend: BackendList,
//     "Testing & QA": TestingAndQAList,
//     "Cloud & DevOps": cloudList,
//     Mobility: Mobility,
//     Databases: DatabaseList,
//   };

//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   const preloadImage = (src) => {
//     return new Promise((resolve, reject) => {
//       const img = new Image();
//       img.src = src;
//       img.onload = resolve;
//       img.onerror = reject;
//     });
//   };

//   useEffect(() => {
//     const allImages = Object.values(listMap)
//       .flat()
//       .map((item) => preloadImage(item.image));

//     Promise.all(allImages)
//       .then(() => {
//         setImagesLoaded(true);
//       })
//       .catch((error) => {
//         console.error("Failed to preload images:", error);
//         setImagesLoaded(true); // Still show content if image preload fails
//       });
//   }, [listMap]);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const thresholdWidth = 1200;

//   const renderContent = () => (
//     <ul className={`brand-list ${brandStyle}`} style={{ gap: "18px" }}>
//       {listMap[activeTab].map((data, index) => (
//         <li
//           className="list_techstack_element"
//           key={index}
//           style={{
//             flexBasis: windowWidth > thresholdWidth ? "13%" : "",
//             backgroundColor: "#1e1e1e",
//             borderRadius: "10px",
//           }}
//         >
//           <img
//             style={{ height: "85px" }}
//             loading="lazy" // Added lazy loading
//             src={`${data.image}`}
//             alt="Tech-stack Image"
//           />
//           <div style={{ fontSize: "14px" }}>{data.name}</div>
//         </li>
//       ))}
//     </ul>
//   );

//   return (
//     <>
//       {imagesLoaded ? (
//         <>
//           <div className="row row--15">
//             <div className="col-lg-12">
//               <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
//                 {[
//                   "Frontend",
//                   "Backend",
//                   "Mobility",
//                   "Cloud & DevOps",
//                   "Databases",
//                   "Testing & QA",
//                 ].map((tab) => (
//                   <li className="list-item" key={tab}>
//                     <button
//                       onClick={() => setActiveTab(tab)}
//                       className={activeTab === tab ? "current" : ""}
//                     >
//                       {tab}
//                     </button>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-lg-12 mt--40">{renderContent()}</div>
//           </div>
//         </>
//       ) : (
//         <div></div>
//       )}
//     </>
//   );
// };

// export default TechStack;
