// import React, { useState, useEffect, useRef, useCallback } from "react";
// import "./Slider.css";

// const imagesList = [
//   "./images/client/BlackBerry.png",
//   "./images/client/Architech-diary.png",
//   "./images/client/auco.png",
//   "./images/client/ABInBev.png",
//   "./images/client/Arham-Enterprise.png",
//   "./images/client/Agrify-foods.png",
//   "./images/client/conkart.png",
//   "./images/client/cylance.png",
// ];

// // Function to determine the number of visible images based on container width
// const getVisibleCount = (width) => {
//   if (width < 300) return 1;
//   if (width < 600) return 2;
//   if (width < 900) return 3;
//   if (width < 1200) return 4;
//   if (width < 1500) return 6;
//   if (width < 1800) return 7;
//   return 7;
// };

// const SliderOne = () => {
//   const [currentIndex, setCurrentIndex] = useState(imagesList.length); // Start at the beginning of the duplicated list
//   const [visibleCount, setVisibleCount] = useState(
//     getVisibleCount(window.innerWidth)
//   );
//   const sliderRef = useRef(null);
//   const slideIntervalRef = useRef(null);

//   const handleResize = useCallback(() => {
//     if (sliderRef.current) {
//       const width = sliderRef.current.clientWidth;
//       setVisibleCount(getVisibleCount(width));
//     }
//   }, []);

//   useEffect(() => {
//     handleResize();
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, [handleResize]);

//   const imageWidth = sliderRef.current
//     ? sliderRef.current.clientWidth / visibleCount
//     : 0;

//   const duplicatedImages = [
//     ...imagesList,
//     ...imagesList,
//     ...imagesList,
//     ...imagesList,
//   ];
//   const totalImages = duplicatedImages.length;

//   const nextSlide = useCallback(() => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
//   }, [totalImages]);

//   const prevSlide = useCallback(() => {
//     setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
//   }, [totalImages]);

//   useEffect(() => {
//     slideIntervalRef.current = setInterval(nextSlide, 1500); // Change slide every 1.5 seconds
//     return () => clearInterval(slideIntervalRef.current);
//   }, [nextSlide]);

//   useEffect(() => {
//     const handleTransitionEnd = () => {
//       if (currentIndex >= imagesList.length * 2) {
//         setCurrentIndex(imagesList.length); // Reset to start of the second set
//       }
//     };

//     const sliderWrapper = sliderRef.current.querySelector(
//       ".image-client-slider-wrapper"
//     );
//     sliderWrapper.addEventListener("transitionend", handleTransitionEnd);
//     return () =>
//       sliderWrapper.removeEventListener("transitionend", handleTransitionEnd);
//   }, [currentIndex]);

//   return (
//     <div className="image-client-slider" ref={sliderRef}>
//       <div
//         className="image-client-slider-wrapper"
//         style={{
//           transform: `translateX(-${currentIndex * imageWidth}px)`,
//           width: `${duplicatedImages.length * imageWidth}px`,
//           transition: "transform 0.5s ease-in-out",
//         }}
//       >
//         {duplicatedImages.map((src, index) => (
//           <div
//             className="image-client-slide"
//             key={index}
//             style={{ width: `${imageWidth}px` }}
//           >
//             <img src={src} alt={`slide ${index}`} />
//           </div>
//         ))}
//       </div>
//       <button className="cilent-button image-client-prev" onClick={prevSlide}>
//         ‹
//       </button>
//       <button className="cilent-button image-client-next" onClick={nextSlide}>
//         ›
//       </button>
//     </div>
//   );
// };

// export default SliderOne;

import React from "react";
import "./Slider.css";

// const imagesList = [
//   "./images/client/BlackBerry.png",
//   "./images/client/Architech-diary.png",
//   "./images/client/auco.png",
//   "./images/client/ABInBev.png",
//   "./images/client/Arham-Enterprise.png",
//   "./images/client/Agrify-foods.png",
//   "./images/client/conkart.png",
//   "./images/client/cylance.png",
// ];

const imagesList = [
  "./images/ClientC/blackberry_new.png",
  "./images/clientC/cylance_new.png",
  "./images/clientC/architechDiary.png",
  "./images/clientC/Agrify-Foods_new.png",
  "./images/clientC/arhanEnterprise.png",
  "./images/clientC/auco.png",
  "./images/clientC/AbINBev.png",
  "./images/clientC/conkart.png",
];

const SliderOne = () => {
  // Duplicate the images list to ensure seamless scrolling
  const duplicatedImages = [
    ...imagesList,
    ...imagesList,
    ...imagesList,
    ...imagesList,
    ...imagesList,
  ];
  console.log("duplicatedImages====>", duplicatedImages);
  return (
    <div className="image-client-slider">
      <div className="image-client-slider-wrapper">
        {duplicatedImages.map((src, index) => (
          <div className="image-client-slide" key={index}>
            {console.log("index===>", index)}
            <img src={src} alt={`slide ${index}`} draggable="false" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderOne;
