import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from "./itemProp/BlogProp";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import { Helmet } from "react-helmet";
const BlogGridView = () => {
  return (
    <>
      {/* <SEO title="Blog List || Doob - React Business  Template" /> */}
      <Helmet>
        <title>
          {/* Mplify Tech Services | Who We Are | Know More About Our Culture,Vision
          & Mission */}
          Mplify | Our Perspectives
        </title>
        <meta
          name="description"
          content="Mplify is a software technology company established in 2015. We specialize in providing innovative and dependable business solutions to leading software product companies serving Fortune 500 firms across various industries. Mplify's core values of bottom-up culture, transparency, ethics, and customer-centricity guide their mission to empower employees and deliver exceptional service."
        />
        <meta
          name="keywords"
          content="Software Development, Software as a Service (SaaS), Testing as a Service (TaaS), Web App Development, Mobile App Development, Information Technology, Business Solutions, Customer Service, Innovation, Employee Empowerment"
        />
        <meta property="og:url" content="https://mplifytech.com/blogs" />
        <meta
          property="og:title"
          content="Mplify Tech: Innovative Business Solutions for Software Companies"
        />
        <meta
          property="og:description"
          content="Mplify empowers businesses with cutting-edge software solutions and exceptional service. Know more about our services, company values, and mission."
        />
        <meta
          property="og:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />{" "}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MplifyTech" />
        <meta name="twitter:creator" content="@MplifyTech" />
        <meta
          name="twitter:title"
          content="Mplify Tech: Your IT Partner for Growth"
        />
        <meta
          name="twitter:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta
          name="twitter:description"
          content="Mplify empowers businesses with cutting-edge software solutions and exceptional service. Learn more about their services, company values, and mission."
        />
        <meta name="business:contact_data:locality" content="Gandhinagar" />
        <meta
          name="business:contact_data:street_address"
          content="A-323, Siddhraj Zori, Sargasan Cross Road, S.G.Highway, Sargasan"
        />
        <meta
          name="business:contact_data:region"
          content="Gandhinagar, Gujarat"
        />
        <meta name="business:contact_data:country_name" content="India" />
        <meta
          name="business:contact_data:email"
          content="contact@mplifytech.com"
        />
        <meta
          name="business:contact_data:phone_number"
          content="91-9512832436"
        />
        <meta
          name="business:contact_data:website"
          content="https://mplifytech.com"
        />
        <meta
          name="keywords"
          content="Blackberry, AB InBev, auco, Arham Enterprise, Architect's Diary, Cylance, Conkart, Agrify Foods"
        />
        <meta
          name="keywords"
          content="Java, Python, PHP, Laravel, Javascript, Typescript, Node, Next, React, HTML, Wordpress, UI/UX, Dart, Kotlin, Flutter, ASP.Net, Docker, Jenkins, Aws, Playwright, Cypress, Codecept, Selenium, Appium, Robot, PyTest, TestNG"
        />
        <meta name="business:contact_data:postal_code" content="382421" />
        <link rel="canonical" href="https://mplifytech.com/blogs"></link>
        <meta name="author" content="Mplify Tech Services Pvt. Ltd." />
      </Helmet>
      <Layout>
        {/* <BreadcrumbOne
          title="Blog List"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Blog List"
        /> */}
        <div className="rwt-contact-area rn-section-gap-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Blogs"
                  title="Know More About Trending Topics"
                  description=""
                />
              </div>
            </div>
            <div className="main-content">
              {/* Start Blog Area  */}
              <div className="rn-blog-area rn-section-gap-60">
                <div className="container">
                  <div className="row mt_dec--30">
                    <BlogProp
                      column="col-lg-4 col-md-6 col-12 mt--30"
                      StyleVarProp="box-card-style-default"
                    />
                  </div>
                </div>
              </div>
              {/* End Blog Area  */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BlogGridView;
