import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7334.962468768546!2d72.61208511662461!3d23.189126515880027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c298841b98eed%3A0x6bcb3f27c88a2025!2sMplify%20Tech%20Services%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1710418377876!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
