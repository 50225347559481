import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
  {
    form: "Embrace Continuous Learning.",
    description:
      "We believe in fostering a culture of continuous learning. We offer opportunities to develop new skills through conferences, workshops, and internal training programs.",
    image: "./images/tab/tabs-03.webp",
  },
  {
    form: "Your Contribution Counts",
    description:
      "We encourage innovative thinking and empower our employees to contribute their ideas.",
    image: "./images/tab/tabs-02.webp",
  },
  {
    form: "Work-Life Balance, We Understand.",
    description:
      "We understand the importance of maintaining a healthy work-life balance.",
    image: "./images/tab/tabs-04.webp",
  },
];

const TestimonialThree = ({ teamStyle }) => {
  const sliderSettings = {
    ...slickDot, // Existing custom settings you may have
    autoplay: true, // Enable autoplay
    autoplaySpeed: 20000, // Set autoplay speed to 30 seconds (30000 ms)
    dots: true, // Enable dots if you want them
    arrows: true, // Enable arrows if needed
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider
          className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60"
          {...sliderSettings}
        >
          {TestimonialData.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                  <div className="content mt_sm--40">
                    <h4 className="title">{data.form}</h4>
                    <li
                      style={{ fontSize: "16px", listStyle: "none" }}
                      className="description"
                    >
                      {data.description}
                    </li>
                    <div className="client-info">
                      {/* Additional client info can go here */}
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                  <div className="">
                    <img
                      className="w-100"
                      src={data.image}
                      alt="Corporate React Template"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialThree;
