import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/scss/mplify/JobDescription.module.scss"; // Assuming SCSS file exists
import LoaderButton from "../../../components/loader";

const JobDetails = ({ jobId, setLoading }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/jobs/${jobId}`
        );
        if (!response.ok) {
          navigate("/careers");
        }
        const data = await response.json();
        setPageLoading(false);
        if (data && data.status == 200 && data.data.hiring) {
          setData(data.data);
          setLoading(false);
        } else {
          navigate("/careers");
        }
      } catch (error) {
        navigate("/careers");
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  if (data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {pageLoading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderButton />
          </div>
        </>
      ) : (
        <>
          <div className={styles.jobDescription}>
            <h2 className="title">Join Our Team</h2>
            <div className={styles.jobDetails}>
              <h3>{data.title}</h3>
              <ul className={styles.jobInfoList}>
                <li>
                  <span className={styles.detailLabel}>Location:</span>{" "}
                  {data.location}
                </li>
                <li>
                  <span className={styles.detailLabel}>Experience:</span>{" "}
                  {data.experience}
                </li>
              </ul>
            </div>
            <div className={styles.contentWrapper}>
              <h4>Job Objective</h4>
              <p>{data.roleObjective}</p>
            </div>
            <div className={styles.contentWrapper}>
              {data && data.responsibilities && (
                <div className={styles.responsibilities}>
                  <h4>Responsibilities</h4>
                  <ul>
                    {data.responsibilities.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {data && data.primarySkillSet && (
              <div className={styles.responsibilities}>
                <h4>Skills</h4>
                <ul>
                  {data.primarySkillSet.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {data && data.secondarySkillSet && (
              <div className={styles.responsibilities}>
                <ul>
                  {data.secondarySkillSet.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            )}

            {data && data.qualificationName && (
              <div className={styles.responsibilities}>
                <h4>Qualification</h4>

                <ul>
                  {data.qualificationName.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {data && data.jobType && (
              <div className={styles.qualificationSection}>
                <h4>Job Type</h4>
                <p>{data.jobType}</p>
              </div>
            )}
            {data && data.businessHour && (
              <div className={styles.qualificationSection}>
                <h4>Business Hours</h4>
                <p>{data.businessHour}</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default JobDetails;
