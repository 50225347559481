import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ProductList from "./ProductList";

const ProductTab = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button className="test-light-button">
                            Mobile Application
                          </button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button className="test-light-button">
                            Web Application
                          </button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <ProductList
                        column="col-lg-4 col-md-6 col-12"
                        teamStyle="team-style-default style-two"
                        type="mobile"
                      />
                    </TabPanel>
                    <TabPanel>
                      <ProductList
                        column="col-lg-4 col-md-6 col-12"
                        teamStyle="team-style-default style-two"
                        type="web"
                      />
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ProductTab;
