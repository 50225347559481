import React from "react";
import { Helmet } from "react-helmet";
import SEO from "../../common/SEO";
import { Link } from "react-router-dom";
import HeaderOne from "../../common/header/HeaderOne";
import Separator from "../../elements/separator/Separator";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import SlpitOne from "../../elements/split/SlpitOne";
import Slider from "react-slick";
import { BannerActivation } from "../../utils/script";
import BannerData from "../../data/slider/HomePageSlider.json";
import ProductTab from "../../common/mplify/product/ProductTab";
import ServiceTwo from "../../elements/service/ServiceTwo";
import WhatWeDo from "../../elements/service/WhatWeDo";
import BrandFour from "../../elements/brand/BrandFour";
import BrandThree from "../../elements/brand/BrandThree";
import BrandOne from "../../elements/brand/BrandOne";
import SliderOne from "../../elements/brand/ImageSlider";
import TechStack from "../../elements/Techstack/Techstack";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          {/* Mplify Tech Services | Robust & Scalable Solutions | Mobile & Web Apps
          Development */}
          Mplify | Let's amplify IT
        </title>{" "}
        <meta
          name="description"
          content="Mplify Tech Services offers comprehensive IT solutions. We deliver high-quality, reliable, and cost-effective solutions to help your business thrive. We have expertise in SDaaS, TaaS, Web & Mobile app development. Know more about our products BLENDIN, MYT, MangOO, JOYREJOY , and Vinty."
        />
        <meta
          name="keywords"
          content="IT solutions Gandhinagar, website development Gandhinagar, software development Gandhinagar, IT support Gandhinagar, Mplify Tech Services,Mplify,Best IT Solutions,Web Application Development, Web Application,Software Development as a Service,SDaaS,Testing as a Service (TaaS),TaaS"
        />
        <meta
          name="keywords"
          content="Mobile App Development,Web App Development, CI / CD,Requirement Analysis,Quality Assurance,Deployment,Jenkins,Travis,CircleCI, VMs,Docker Containers"
        />
        <meta
          name="keywords"
          content="BLENDIN - Design News Inshort, MYT - Tasks at my location, MangOO - Delivering Happiness,Vinty-Graceful Grips,JOYREJOY - Bringing joy back to pre-loved toys - one sale at a time!"
        />
        <meta
          name="keywords"
          content="best IT company in gandhinagar, it jobs in gandhinagar, jobs in it companies for freshers gandhinagar, it companies hiring, it company job vacancy, it job vacancy gandhinagar"
        />
        <meta name="author" content="Mplify Tech Services" />
        <meta name="og:url" content="https://mplifytech.com" />
        <meta
          property="og:title"
          content="Mplify Tech Services: IT Solutions for Your Business Growth"
        />
        <meta
          property="og:site_name"
          content="Mplify Tech Services - Delivering Quality With Execellence"
        />
        <meta
          property="og:description"
          content="Mplify Tech Services partners with businesses to deliver tailored IT solutions that drive success. We offer expertise in website development, software development, IT support, and more. Get a free consultation today!"
        />{" "}
        <meta
          property="og:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta name="twitter:site" content="@MplifyTech" />{" "}
        <meta
          name="twitter:title"
          content="Mplify Tech: Your Trusted IT Partner"
        />{" "}
        <meta name="twitter:creator" content="@MplifyTech" />
        <meta
          name="twitter:description"
          content="Mplify Tech empowers businesses with reliable IT solutions. We bring in process and development expertese to provide custom and robust IT Solutions."
        />{" "}
        <meta
          name="twitter:image"
          content="https://mplifytech.com/images/mplify/logo/logo-dark.png"
        />
        <meta name="business:contact_data:locality" content="Gandhinagar" />
        <meta
          name="business:contact_data:street_address"
          content="A-323, Siddhraj Zori, Sargasan Cross Road, S.G.Highway, Sargasan"
        />
        <meta
          name="business:contact_data:region"
          content="Gandhinagar, Gujarat"
        />
        <meta name="business:contact_data:country_name" content="India" />
        <meta
          name="business:contact_data:email"
          content="contact@mplifytech.com"
        />
        <meta
          name="business:contact_data:phone_number"
          content="91-9512832436"
        />
        <meta
          name="business:contact_data:website"
          content="https://mplifytech.com"
        />
        <meta name="business:contact_data:postal_code" content="382421" />
        <link rel="canonical" href="https://mplifytech.com"></link>
        <meta
          name="keywords"
          content="Blackberry, AB InBev, auco, Arham Enterprise, Architect's Diary, Cylance, Conkart, Agrify Foods"
        />
        <meta
          name="keywords"
          content="Java, Python, PHP, Laravel, Javascript, Typescript, Node, Next, React, HTML, Wordpress, UI/UX, Dart, Kotlin, Flutter, ASP.Net, Docker, Jenkins, Aws, Playwright, Cypress, Codecept, Selenium, Appium, Robot, PyTest, TestNG"
        />
      </Helmet>
      <SEO title="Home" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <Slider
          //className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
          className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData &&
            BannerData.map((data, index) => (
              <div key={index} className="single-slide ">
                <div
                  className="height-850 bg-overlay bg_image"
                  style={{
                    backgroundImage: data.image
                      ? `url(${process.env.PUBLIC_URL} ${data.image})`
                      : "none",
                  }}
                >
                  <div className="container">
                    <div className="row row--30 align-items-center">
                      <div className="col-lg-12">
                        <div className="inner text-center">
                          {data.title && (
                            <h1
                              className="title"
                              dangerouslySetInnerHTML={{ __html: data.title }}
                            ></h1>
                          )}
                          {data.description && (
                            <p
                              className="description"
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            ></p>
                          )}
                          <div className="button-group mt--30">
                            {data.link && data.button_name && (
                              <Link
                                className="btn-default btn-large round"
                                to={data.link}
                              >
                                {data.button_name}
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>

        {/* <Separator /> */}

        <div
          className="service-area rn-section-gapBottom"
          style={{ marginTop: "50px" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Our technical expertise can seamlessly augment your
                    technology needs!
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10 b1 color-gray description">
                  We are dedicated to provide our clients with high-quality
                  software solutions that satisfy their needs and expectations.
                  We are a software development and quality assurance firm. Our
                  staff of skilled developers and testers is knowledgeable in a
                  range of technologies and platforms, allowing us to provide
                  custom solutions that meet the distinct business needs of each
                  of our clients.
                </p>
                <div className="readmore-btn">
                  <Link className="btn-read-more" to="our-services">
                    <span>View More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Services"
                  title="We find our niche in the following four services"
                  description=""
                />
              </div>
            </div>
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we do?"
                  title="We develop technology platforms for your success!"
                  description=""
                />
              </div>
            </div>

            <WhatWeDo
              serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* <Separator /> */}

        <SlpitOne />

        <div className="rwt-portfolio-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Products"
                  title="What We Built"
                  description=""
                />
              </div>
            </div>

            <ProductTab />
          </div>
        </div>

        {/* <Separator /> */}

        {/* Start Brand Area  */}
        <div className="rwt-brand-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Clients"
                  title="Some of our Awesome Clients"
                  description=""
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-12 mt--40">
                <BrandThree brandStyle="brand-style-1" />
              </div>
            </div> */}
            {/* Start Brand Area  */}
            <div className="rwt-brand-area ptb--gap-20">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 mt--10">
                    {/* <BrandFour brandStyle="brand-style-2" /> */}
                    <SliderOne visibleCount={6} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* <Separator /> */}

        {/* Start Brand Area  */}
        <div className="rwt-brand-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Expertise in"
                  // title="Modern Technologies for Crafting Robust Solutions"
                  description=""
                />
              </div>
            </div>
            {/* Start Brand Area  */}

            <div className="row">
              <div className="col-lg-12 mt--40">
                {/* <BrandOne brandStyle="brand-style-2" /> */}
                <TechStack brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}
        {/* <Separator /> */}
        {/* <FooterTwo /> */}
      </main>
    </>
  );
};
export default Home;
